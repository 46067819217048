<template>
  <v-card>
    <v-tabs dense vertical>
      <v-navigation-drawer permanent expand-on-hover>
        <v-tab class="tabs">
          <v-icon class="pr-4">mdi-checkbox-multiple-outline</v-icon> CICLO PRODUZIONE
        </v-tab>
        <v-tab class="tabs"> <v-icon class="pr-4">mdi-text</v-icon> DISTINTA BASE </v-tab>

        <v-tab class="tabs">
          <v-icon class="pr-4">mdi-handshake-outline</v-icon> IMPEGNO
        </v-tab>
        <v-tab class="tabs">
          <v-icon class="pr-4">mdi-notebook-edit-outline</v-icon> ANNOTAZIONI
        </v-tab>
      </v-navigation-drawer>

      <v-tab-item :transition="false" :reverse-transition="false">
        <v-card flat>
          <v-card-text class="px-0 py-0">
            <Cicolo
              @delFornitura="delFornitura($event)"
              @delPersonale="delPersonale($event)"
              @delMacchina="delMacchina($event)"
              @deletedCiclo="deletedCicli($event)"
              @deleteAllFasi="deleteAllFasi($event)"
              :IdArticolo="this.idArticolo"
              :cicloData="this.CicloData"
            />
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item :transition="false" :reverse-transition="false">
        <v-card flat>
          <v-card-text class="px-0 py-0">
            <Distinta
              v-on:update="updateDrawer($event)"
              :IdArticolo="this.idArticolo"
              :CompleteData="this.CicloData"
              @delDistinta="delDistinta($event)"
            />
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item :transition="false" :reverse-transition="false">
        <v-card flat>
          <v-card-text class="px-0 py-0">
            <Impegno :CompleteData="this.completeData" />
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item :transition="false" :reverse-transition="false">
        <v-card flat>
          <v-card-text class="px-0 py-0">
            <Annotazioni />
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
import Cicolo from "./CommessaCicloProdNew";
import Distinta from "./CommesaDistinta";
import Impegno from "./CommesaImpegno";
import Annotazioni from "./CommesaAnnotazioni";

export default {
  props: ["idArticolo", "CicloData", "completeData"],
  components: {
    Cicolo,
    Distinta,
    Impegno,
    Annotazioni,
  },
  methods: {
    delDistinta(event) {
      this.$emit("delDistinta", event);
    },
    updateDrawer(val) {
      this.$emit("updateDrawer", val);
    },

    deletedCicli(event) {
      this.$emit("DelCiclo", event);
    },
    delMacchina(event) {
      this.$emit("delMacchina", event);
    },
    delPersonale(event) {
      this.$emit("delPersonale", event);
    },
    delFornitura(event) {
      this.$emit("delFornitura", event);
    },
    deleteAllFasi(event) {
      this.$emit("deleteAllFasi", event);
    },
  },
};
</script>

<style lang="scss" scoped>
.tabs {
  justify-content: start !important;
}
</style>
