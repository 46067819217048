<template>
  <v-container fluid class="py-0">
    <v-dialog
      :scrollable="false"
      hide-overlay
      persistent
      v-model="dialog"
      width="1100"
    >
      <v-card class="">
        <v-toolbar dense class="indigo darken-1 headline">
          <v-toolbar-title class="white--text"
            >Aggiungi Impegno</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr-n8"
                @click="
                  dialog = false;
                  submit();
                "
                style="float: right"
                small
              >
                <v-icon v-on="on" v-bind="attrs" dark tile color="success"
                  >mdi-floppy</v-icon
                >
              </v-btn>
            </template>
            <span>Salva</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="dialog = false" small>
                <v-icon
                  v-on="on"
                  v-bind="attrs"
                  depressed
                  class=""
                  color="#c00000"
                  >mdi-window-close</v-icon
                >
              </v-btn>
            </template>
            <span>Cancellare </span>
          </v-tooltip>
        </v-toolbar>

        <v-card-text>
          <v-form>
            <v-container>
              <v-row>
                <v-col class="py-0" cols="2" md="2" sm="3">
                  <v-text-field
                    v-model="giacenza"
                    placeholder="Giacenza"
                    label="Giacenza"
                    required
                  ></v-text-field>
                </v-col>

                <v-col class="py-0" cols="4" md="2" sm="2">
                  <v-text-field
                    type="number"
                    v-model="qta_impegnata"
                    placeholder="Q.ta Impegnata"
                    label="Q.ta Impegnata"
                    required
                  ></v-text-field>
                </v-col>

                <v-col class="py-0" cols="3" md="2" sm="2">
                  <v-text-field
                    v-model="qta_disponible"
                    type="nmuber"
                    placeholder="Q.ta Disponible"
                    label="Q.ta Disponible"
                    required
                  ></v-text-field>
                </v-col>

                <v-col class="py-0" sm="2" cols="12" md="3">
                  <v-text-field
                    class="shrink"
                    v-model="articolo"
                    type="text"
                    placeholder="Articolo"
                    label="Articolo"
                    required
                  ></v-text-field>
                </v-col>

                <v-col class="d-flex py-0" sm="2" cols="12" md="3">
                  <v-text-field
                    class="shrink"
                    v-model="lotto"
                    type="number"
                    placeholder="Lotto"
                    label="Lotto"
                    required
                  ></v-text-field>
                </v-col>

                <v-col class="d-flex mt-n3" sm="3" cols="12" md="2">
                  <span
                    style="
                      font-size: 12px;
                      color: rgba(0, 0, 0, 0.6);
                      margin-left: -0.2rem;
                      padding-left: -0.2rem;
                    "
                    >Esito Collaudo</span
                  >
                  <v-switch
                    style="margin-left: -4rem; margin-top: 1rem"
                    v-model="esito_collaudo"
                    :false-value="0"
                    :true-value="1"
                  ></v-switch>
                </v-col>

                <v-col
                  style="margin-left: -5rem !important"
                  class="d-flex py-0"
                  sm="2"
                  cols="12"
                  md="2"
                >
                  <v-text-field
                    v-model="cod_timbro"
                    type="text"
                    placeholder="Cod. Timbro"
                    label="Cod. Timbro"
                    required
                  ></v-text-field>
                </v-col>
                <v-col class="d-flex py-0" sm="3" cols="12" md="2">
                  <span
                    style="
                      font-size: 12px;
                      color: rgba(0, 0, 0, 0.6);
                      margin-left: -0.2rem;
                      padding-left: -0.2rem;
                    "
                    >Esterna</span
                  >
                  <v-switch
                    style="margin-left: -2.6rem; margin-top: 1rem"
                    v-model="esterna"
                    :false-value="0"
                    :true-value="1"
                  ></v-switch>
                </v-col>

                <v-col
                  style="margin-left: -7rem !important"
                  sm="2"
                  cols="12"
                  md="2"
                  class="py-0"
                >
                  <v-text-field
                    v-model="magazzino"
                    type="text"
                    placeholder="Magazzino"
                    label="Magazzino"
                    required
                  ></v-text-field>
                </v-col>

                <v-col sm="2" cols="12" md="2" class="py-0">
                  <v-text-field
                    class=""
                    v-model="qty_impegno"
                    type="number"
                    placeholder="Q.ta Impegno"
                    label="Q.ta Impegno"
                    required
                  ></v-text-field>
                </v-col>

                <v-col class="py-0" sm="3" cols="12" md="2">
                  <v-text-field
                    class="shrink"
                    v-model="qty_scaricata"
                    type="number"
                    label="Q.ta Scaricata"
                    placeholder="Q.ta Scaricata"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-spacer></v-spacer>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-row>
      <v-col>
        <v-card>
          <v-toolbar dense class="indigo darken-1 headline">
            <v-toolbar-title class="white--text">
              IMPEGNO MATERIA PRIMA
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class=""
                  style="margin-left: 1rem"
                  @click="dialog8 = false"
                  small
                >
                  <v-icon v-on="on" v-bind="attrs" depressed color="#1a80b6"
                    >mdi-autorenew</v-icon
                  >
                </v-btn>
              </template>
              <span>Rinnovo Automatico</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn small class="ml-3" @click="dialog = true">
                  <v-icon v-on="on" v-bind="attrs" style="color: #1a80b6"
                    >mdi-plus</v-icon
                  >
                </v-btn>
              </template>
              <span>per aggiungere un nuovo commesa Impegno</span>
            </v-tooltip>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-row>
              <v-col md="12">
                <v-data-table :headers="headers" :items="items">
                  <template v-slot:[`item.edit`]="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          small
                          style="float: left"
                          @click="handleclick(item)"
                        >
                          <v-icon v-on="on" v-bind="attrs" color="#009999"
                            >mdi-pencil</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>Aggiornare</span>
                    </v-tooltip>
                  </template>

                  <template v-slot:[`item.delete`]="{}">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn small>
                          <v-icon v-on="on" v-bind="attrs" color="red">
                            mdi-window-close
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Cancellare</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: ["IdArticolo", "CompleteData"],
  data() {
    return {
      dialog: false,
      giacenza: 0,
      qta_impegnata: 0,
      qta_disponible: 0,
      articolo: "",
      lotto: 0,
      esito_collaudo: 0,
      lotto_fornitore: 0,
      esterna: false,
      cod_timbro: "",
      magazzino: "",
      qty_impegno: 0,
      qty_scaricata: 0,
      items: [],
      headers: [
        {
          align: "start",
          value: "edit",
          sortable: false,
        },
        {
          text: "Giacenza",
          align: "start",
          value: "giacenza",
          sortable: false,
        },
        {
          text: "Q.ta Impegnata",
          value: "qta_impegnata",
          sortable: false,
        },

        {
          text: "Qunatita disponible",
          value: "qta_disponible",
          sortable: false,
        },
        {
          text: "Articolo",
          value: "articolo",
          sortable: false,
        },
        {
          text: "Lotto",
          value: "lotto",
          sortable: false,
        },
        {
          text: "Esito Collaudo",
          value: "esito_collaudo",
          sortable: false,
        },
        {
          text: "Lotto Fornitore",
          value: "lotto_fornitore",
          sortable: false,
        },
        {
          text: "Codice Timbro",
          value: "cod_timbro",
          sortable: false,
        },
        {
          text: "Magazzino",
          value: "magazzino",
          sortable: false,
        },
        {
          text: "Quantita Impegno",
          value: "qty_impegno",
          sortable: false,
        },
        {
          text: "",
          value: "setting",
          sortable: false,
        },
        {
          text: "Quantita Scaricata",
          value: "qty_scaricata",
          sortable: false,
        },
        {
          text: "",
          value: "delete",
          // text: '', fixed: true, width: '100px',
          sortable: false,
        },
      ],
    };
  },

  methods: {
    handleclick(item) {
      this.dialog = true;
      this.id_commessa_impegno = item.id_commessa_impegno;
      this.giacenza = item.giacenza;
      this.qta_impegnata = item.qta_impegnata;
      this.qta_disponible = item.qta_disponible;
      this.articolo = item.articolo;
      this.lotto = item.lotto;
      this.esito_collaudo = item.esito_collaudo;
      this.lotto_fornitore = item.lotto_fornitore;
      this.esterna = item.esterna;
      this.cod_timbro = item.cod_timbro;
      this.magazzino = item.magazzino;
      this.qty_impegno = item.qty_impegno;
      this.qty_scaricata = item.qty_scaricata;
    },

    submit() {
      let data = {};

      data.id_commessa_impegno = -1;
      data.giacenza = this.giacenza;
      data.qta_impegnata = this.qta_impegnata;
      data.qta_disponible = this.qta_disponible;
      data.articolo = this.articolo;
      data.lotto = this.lotto;
      data.esito_collaudo = this.esito_collaudo;
      data.lotto_fornitore = this.lotto_fornitore;
      data.esterna = this.esterna;
      data.cod_timbro = this.cod_timbro;
      data.magazzino = this.magazzino;
      data.qty_impegno = this.qty_impegno;
      data.qty_scaricata = this.qty_scaricata;
      data.validita = 1;
      this.items.push(data);

      this.giacenza = "";
      this.qta_impegnata = 0;
      this.qta_disponible = 0;
      this.articolo = "";
      this.lotto = 0;
      this.esito_collaudo = 0;
      this.lotto_fornitore = 0;
      this.esterna = 0;
      this.cod_timbro = "";
      this.magazzino = "";
      this.qty_impegno = 0;
      this.qty_scaricata = 0;
    },
  },
  mounted() {
    this.items = this.CompleteData.commessaImpegno;
  },
};
</script>
