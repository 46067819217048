<template>
  <v-container fluid class="py-0">
    <!-- In order to see the detail of child article -->
    <v-dialog persistent v-model="dialogArticoloDettaglio" width="unset">
      <v-card>
        <v-toolbar dense class="headline indigo darken-1" primary-title>
          <v-toolbar-title class="white--text"
            >Articolo Dettaglio</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                @click="
                  closedetailModal();
                  dialogArticoloDettaglio = false;
                "
              >
                <v-icon
                  v-on="on"
                  v-bind="attrs"
                  color="error"
                  depressed
                  class=""
                  >mdi-window-close</v-icon
                >
              </v-btn>
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text
          style="max-height: 600px; overflow-y: auto; overflow-x: hidden"
          class="mt-6"
        >
          <Articolo
            :articoloId="this.recursiveId"
            :key="this.recursiveId"
            :name="this.recursiveId"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- in order to add children -->
    <v-dialog
      content-class="hideoverflow"
      persistent
      hide-overlay
      v-model="dialog"
      width="900"
    >
      <v-card>
        <v-toolbar dense class="headline indigo darken-1" primary-title>
          <v-toolbar-title class="white--text"
            >Commessa Distinta Base</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <Button
            classe_bottone="ml-1"
            colore_icona="success"
            idForm="COMMESSA_DISTINTA"
            idOggetto="SALVA_COMMESA_DISTINTA"
            mdi_icona="mdi-floppy"
            testo_tooltip="Salva"
            v-on:buttonClick="saveDistinta"
          />
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="hideModal" small>
                <v-icon class="" v-on="on" v-bind="attrs" color="error"
                  >mdi-window-close</v-icon
                >
              </v-btn>
            </template>
            <span>Cancallare</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-subtitle>
          <v-form v-on:submit.prevent="searchDistintaBase" class="mt-n2 ml-n1">
            <v-container>
              <v-row>
                <v-col class="d-flex" cols="12" md="3">
                  <v-text-field
                    type="text"
                    v-model="cod_articolo"
                    class="shrink"
                    label="Ricerca Componente"
                    placeholder="Ricerca Componente"
                    required
                  ></v-text-field>
                </v-col>
                <v-col class md="1">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="mt-5" @click="searchDistintaBase" small>
                        <v-icon
                          v-on="on"
                          v-bind="attrs"
                          color="#1a80b6"
                          size="32"
                          >mdi-magnify</v-icon
                        >
                      </v-btn>
                    </template>
                    <span>Cerca </span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-subtitle>
        <v-card-text class="mt-n16" style="max-height: 450px; overflow-y: auto">
          <v-container fluid>
            <v-row>
              <v-col md="12">
                <v-data-table
                  style="width: 100% !important"
                  :headers="headers"
                  :items="items"
                  class="elevation-1"
                  :dense="true"
                >
                  <template v-slot:[`item.quantita`]="props">
                    <v-text-field
                      solo
                      flat
                      min="0"
                      dense
                      v-model="props.item.quantita"
                      type="number"
                      name="quantita"
                    ></v-text-field>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- to update single children of distinta -->
    <v-dialog
      content-class="hideoverflow"
      persistent
      v-model="dialogDistintaBase"
      width="700"
    >
      <v-card>
        <v-toolbar dense class="headline indigo darken-1" primary-title>
          <v-toolbar-title class="white--text">Distinta Base</v-toolbar-title>
          <v-spacer></v-spacer>
          <Button
            classe_bottone="ml-1"
            colore_icona="success"
            idForm="COMMESSA_DISTINTA"
            idOggetto="SALVA_COMMESA_DISTINTA"
            mdi_icona="mdi-floppy"
            testo_tooltip="Salva"
            v-on:buttonClick="updateDistinta"
          />
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn small @click="dialogDistintaBase = false">
                <v-icon class="" v-on="on" v-bind="attrs" color="error"
                  >mdi-window-close</v-icon
                >
              </v-btn>
            </template>
            <span>Cancallare</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-subtitle>
          <v-form v-on:submit.prevent="searchDistintaBase" class="mt-n ml-n1">
            <v-container>
              <v-row>
                <v-col class="d-flex" cols="12" md="3">
                  <v-text-field
                    :disabled="this.disablesearch === true"
                    type="text"
                    v-model="cod_articolo"
                    class="shrink"
                    label="Ricerca Componente"
                    placeholder="Ricerca Componente"
                    required
                  ></v-text-field>
                </v-col>
                <v-col class md="1">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="mt-5" @click="searchDistintaBase" small>
                        <v-icon
                          v-on="on"
                          v-bind="attrs"
                          color="#1a80b6"
                          size="32"
                          >mdi-magnify</v-icon
                        >
                      </v-btn>
                    </template>
                    <span>Cerca </span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-subtitle>
        <v-card-text style="max-height: 600px; overflow-y: auto">
          <v-container fluid>
            <v-divider></v-divider>
            <v-row>
              <v-col md="12">
                <v-data-table
                  :loading="LoadingVal"
                  :headers="headers"
                  :items="items"
                  class="elevation-1"
                  :dense="true"
                >
                  <v-progress-linear
                    v-show="progressBar"
                    slot="progress"
                    color="blue"
                    indeterminate
                  ></v-progress-linear>
                  <template v-slot:[`item.quantita`]="props">
                    <v-text-field
                      solo
                      flat
                      min="0"
                      dense
                      v-model="props.item.quantita"
                      type="number"
                      name="quantita"
                    ></v-text-field>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- In order to single element -->
    <v-dialog
      persistent
      hide-overlay
      v-model="dialogEliminaDistintaBase"
      width="400"
    >
      <v-card>
        <v-toolbar dense class="headline indigo lighten-1" primary-title>
          <v-toolbar-title class="white--text"
            >elimina la distinta base</v-toolbar-title
          >
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-container fluid>
          <v-row>
            <v-col md="1">
              <v-icon color="error">mdi-exclamation-thick</v-icon>
            </v-col>
            <v-col>
              <p>
                Attenzione. Confermi la rimozione del componenti: N':
                {{ this.deletingQuantity }}-{{ this.deletingItemDetail }}
              </p>
            </v-col>
            <v-col></v-col>
          </v-row>
          <v-row>
            <v-col md="5"></v-col>
            <v-col md="3">
              <v-btn
                dark
                tile
                class="mx-auto"
                @click="
                  deleteItem();
                  dialogEliminaDistintaBase = false;
                "
                color="green"
                >Invia</v-btn
              >
            </v-col>
            <v-col md="2">
              <v-btn
                dark
                tile
                class="mr-5"
                color="error"
                @click="dialogEliminaDistintaBase = false"
                >Anulla</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <!-- in order to show alerts -->
    <v-snackbar
      content-class="snackbar"
      :color="color"
      right
      v-model="snackbar"
      :timeout="3000"
    >
      <v-icon :size="64">{{ mainIcon }}</v-icon>
      <h2 class="ml-16">{{ snackbar_text }}</h2>
      <v-btn
        @click="snackbar = false"
        fab
        dark
        text
        small
        style="
          margin-top: -6rem;
          float: right !important;
          margin-right: -1rem !important;
        "
      >
        <v-icon>mdi-window-close</v-icon>
      </v-btn>
    </v-snackbar>
    <!-- Dsitinta Main  -->
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-toolbar dense class="indigo darken-1 rounded-t" primary-title>
            <v-toolbar-title class="white--text"
              >Commessa Distinta Base</v-toolbar-title
            >
            <v-spacer></v-spacer>
            <v-btn
              v-if="!on"
              @click="showIcons"
              class="ml-16"
              color="white"
              fab
              top
              tile
              small
            >
              <v-icon color="primary">mdi-arrow-collapse</v-icon>
            </v-btn>
            <v-btn
              v-if="on"
              fab
              top
              tile
              @click="hideIcons"
              class="ml-16"
              color="white"
              small
            >
              <v-icon color="primary">mdi-arrow-expand</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text class="text-left">
            <v-progress-circular
              class=""
              v-show="progressBar1"
              slot="progress"
              color="blue"
              indeterminate
            ></v-progress-circular>
            <v-treeview
              activatable
              shaped
              hoverable
              :open.sync="open"
              :items="distintaBaseList"
              item-key="id"
              content="6"
              open-on-click
            >
              <template v-slot:prepend="{ item, open }">
                <v-icon v-if="item.id == 0">{{
                  open ? "mdi-buffer" : "mdi-buffer"
                }}</v-icon>
                <v-icon v-if="item.id > 0">{{
                  open ? "mdi-text-short" : "mdi-text-short"
                }}</v-icon>
                <template v-if="item.id >= -1"
                  >N° {{ item.quantita }} {{ item.cod_articolo }}</template
                >
              </template>
              <template
                v-show="hideicons === true"
                slot="append"
                slot-scope="{ item }"
              >
                <span v-show="item.id_rif === -1">
                  <Button
                    colore_icona="primary"
                    idForm="COMMESSA_DISTINTA"
                    idOggetto="CREA_COMMESA_DISTINTA"
                    mdi_icona=" mdi-plus"
                    testo_tooltip="Inserisci Componente"
                    v-on:buttonClick="showModal"
                  />
                </span>

                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      @click="getDetail(item)"
                      v-on="on"
                      v-show="item.id_rif == 0"
                      small
                    >
                      <v-icon color="blue">mdi-eye</v-icon>
                    </v-btn>
                  </template>
                  <span>Dettaglio Articolo</span>
                </v-tooltip>

                <span v-if="item.id_rif === 0">
                  <Button
                    classe_bottone="ml-1"
                    colore_icona="primary"
                    idForm="COMMESSA_DISTINTA"
                    idOggetto="MODIFICA_COMMESA_DISTINTA"
                    mdi_icona=" mdi-pencil"
                    testo_tooltip="Modifica Componente"
                    v-on:buttonClick="updateItem(item)"
                  />
                </span>

                <span v-show="item.id_rif === 0">
                  <Button
                    classe_bottone="ml-1"
                    colore_icona="error"
                    idForm="COMMESSA_DISTINTA"
                    idOggetto="ELIMINA_COMMESA_DISTINTA"
                    mdi_icona="mdi-window-close"
                    testo_tooltip="Cancella Componente"
                    v-on:buttonClick="deleteFolder(item)"
                  />
                </span>
              </template>
            </v-treeview>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-divider></v-divider>
  </v-container>
</template>
<script>
import Button from "../../../components/TOOLS/FormComponentPermissions/Button.vue";
// import config from '../../../../public/config'
export default {
  name: "distintaBase",
  props: ["IdArticolo", "CompleteData"],
  components: {
    Button,
    Articolo: () => import("../Articolo/Articolo"),
  },
  data() {
    return {
      // deleted Distinta
      deletedDistinta: [],
      item: "",
      LoadingVal: "",
      progressBar: false,
      // SelectedCom
      selectedcomponent: "",
      progressBar1: true,
      dialogDistintaBase: false,
      ids: [],
      recursiveId: null,
      updatedDistinta: [],
      dialogArticoloDettaglio: false,
      openAll: false,
      on: true,
      findalData: [],
      deletingQuantity: 0,
      deletingItemDetail: "",
      dialogEliminaDistintaBase: false,
      disablesearch: false,
      open: [0],
      hideicons: true,
      quantita: 0,
      items: [],
      cod_articolo: "",
      abilita_distinta_base: 0,
      distintaBaseList: [], //array cartelle
      arrayPadre: [], //array con la lista dei nodi tra la cartella selezionata e la root
      dialog: false,
      strPath: "", //string path della cartella
      strNameFolder: "", //string nuovo nome cartella
      editIdCartella: -1, //int id cartella selezionata
      files: [],
      editedIndexAllegato: -1,
      message_new_allegato_waiting: "",
      date: new Date().toISOString().substr(0, 10),
      // Snackbar Variables
      color: "",
      snackbar: false,
      mainIcon: "",
      snackbar_text: "",
      data: (vm) => ({
        date: new Date().toISOString().substr(0, 10),
        dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
        menu2: false,
      }),
      headers: [
        {
          text: "Q.ta Distinta",
          value: "quantita",
        },
        {
          text: "Codice Articolo",
          value: "cod_articolo",
        },
        {
          text: "Descrizione",
          value: "des_articolo",
        },
      ],
    };
  },
  mounted() {
    this.blnRenameFolder = false;
    this.strNameFolder = "";
    this.files = [];
    // getting list of Distinta at initial stage
    let getdistintabase = {
      controller: "Magazzino",
      method: "POST",
      richiesta: {
        action: "getarticolidistintabase",
        token: localStorage.getItem("user_token"),
        id_articolo: this.IdArticolo,
      },
    };
    this.$store.dispatch("api", getdistintabase).then((res) => {
      this.progressBar1 = false;
      this.distintaBaseList = res.data;
      if (this.distintaBaseList.length > 0) {
        this.distintaBaseList[0].children = [];
        this.progressBar1 = false;
        console.log("this.CompleteData", this.CompleteData);
        if (this.CompleteData.commessa_distinta !== []) {
          this.distintaBaseList[0].children = this.CompleteData.commessa_distinta;
        }
      } else {
        this.distintaBaseList.push({ children: [] });
        if (this.CompleteData.commessa_distinta !== []) {
          this.distintaBaseList[0].children = this.CompleteData.commessa_distinta;
        }
      }
      this.distintaBaseList.map((el) => {
        if (el.children.length > 0) {
          el.children.map((el) => {
            this.ids.push(el.id);
            if (el.children.length > 0) {
              el.children.map((el) => {
                this.ids.push(el.id);
              });
            }
          });
        }
        this.ids.push(el.id);
      });
    });
  },
  methods: {
    // Update single element
    updateDistinta() {
      let i = this.distintaBaseList[0].children.findIndex(
        (it) => it === this.selectedcomponent
      );
      this.distintaBaseList[0].children.splice(i, 1, this.selectedcomponent);
      this.snackbar = true;
      this.snackbar_text = "Modifica effettuata con successo!";
      this.color = "green darken-3";
      this.mainIcon = "mdi-check-circle";
      this.dialogDistintaBase = false;
    },
    // getting value of child articolo
    getDetail(item) {
      this.dialogArticoloDettaglio = true;
      this.recursiveId = item.id;
    },
    // to close modal
    closedetailModal() {
      this.dialogArticoloDettaglio = false;
    },
    // Delete one element
    deleteFolder(item) {
      if (item.id_commessa_distinta_base > -1) {
        item.validita = 0;
        this.deletedDistinta.push(item);
        this.$emit("delDistinta", item);
      }
      const i = this.distintaBaseList[0].children.findIndex((it) => it == item);
      this.distintaBaseList[0].children.splice(i, 1);
    },
    // rendering showing icons
    hideIcons() {
      this.hideicons = false;
      this.on = false;
      // this.openAll = true;
      this.open = this.ids;
    },
    // rendering hide icons
    showIcons() {
      this.hideicons = true;
      this.openAll = false;
      this.open = [];
      this.on = true;
    },
    // converting articolo detail for distinta base
    updateItem(val) {
      this.dialogDistintaBase = true;
      this.disablesearch = true;
      delete val.children;
      val.cod_articolo = val.id;
      val.des_articolo = val.name;
      this.selectedcomponent = val;
      this.items = [val];
    },
    // searching articles to add as childrens
    searchDistintaBase(evt) {
      evt.preventDefault();
      let getdistintabase = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getarticoli",
          token: localStorage.getItem("user_token"),
          cod_articolo: this.cod_articolo,
        },
      };
      this.$store.dispatch("api", getdistintabase).then((res) => {
        this.items = res.data.lista;
        if (this.items.length === 0) {
          this.snackbar = true;
          this.snackbar_text = "L'articolo non è stato trovato. Riprova !";
          this.mainIcon = "mdi-close-circle";
          this.color = "red accent-4";
          this.progressBar = false;
          this.LoadingVal = false;
        }
        this.items.filter((el) => el.id_articolo === this.cod_articolo);
        if (this.items[0].id_articolo === this.id_articolo) {
          this.items = [];
          return this.items;
        }
        this.items.map((el) => (el.quantita = 0));
      });
    },
    // in order to open to modal
    showModal() {
      this.dialog = true;
    },
    // Create distinta
    saveDistinta() {
      let filteredObject = [];
      this.items.map((el) => {
        if (el.quantita > 0) {
          filteredObject.push(el);
        }
      });
      console.log("filteredObject", filteredObject);
      filteredObject.map((el) => {
        let getdistintabase = {
          controller: "Magazzino",
          method: "POST",
          richiesta: {
            action: "getarticolidistintabase",
            id_articolo: el.id_articolo,
            token: localStorage.getItem("user_token"),
          },
        };
        this.$store.dispatch("api", getdistintabase).then((res) => {
          let getresult = res.data;
          getresult[0].id_commessa_distinta_base = -1;
          getresult[0].id_commessa = -1;
          getresult[0].id_articolo = this.IdArticolo;
          getresult[0].id_componente = el.id_articolo;
          getresult[0].id = parseInt(getresult[0].id);
          getresult[0].id_articolo = this.IdArticolo;
          getresult[0].quantita = el.quantita;
          getresult[0].id_rif = 0;
          getresult[0].validita = 1;
          if (getresult[0].children[0]) {
            getresult[0].children[0].id_rif = 1;
          }
          el = getresult;
          this.distintaBaseList[0].children.push(el[0]);
          this.$emit("update", this.distintaBaseList);
          this.snackbar = true;
          this.snackbar_text = "Modifica effettuata con successo!";
          this.color = "green darken-3";
          this.mainIcon = "mdi-check-circle";
        });
      });
      this.distintaBaseList.map((el) => {
        if (el.children.length > 0) {
          el.children.map((el) => {
            this.ids.push(el.id);
          });
        }
      });
      this.dialog = false;
      this.items = [];
      this.cod_articolo = "";
    },
    // closing modal
    hideModal() {
      this.dialog = false;
      this.disablesearch = false;
      this.cod_articolo = "";
      this.items = [];
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    /*
            Handles a change on the file upload
          */
  },
};
</script>
<style lang="scss">
.hideoverflow {
  overflow: hidden !important;
}
</style>
