<template>
	<div style="padding: 20px">
		<v-card fluid>
			<!-- Snackbars to show alerts -->
			<v-snackbar content-class="snackbar" :color="snackbar_background" right v-model="snackbar" :timeout="3000">
				<v-icon :color="snackbar_icon_color" :size="64">{{ snackbar_icon }}</v-icon>
				<h2 :style="{ color: snackbar_text_color + '!important' }" class="ml-16">
					{{ snackbar_text }}
				</h2>
				<v-btn
					@click="snackbar = false"
					fab
					dark
					text
					small
					style="
            margin-top: -6rem;
            float: right !important;
            margin-right: -1rem !important;
          "
				>
					<v-icon :color="snackbar_icon_color">mdi-window-close</v-icon>
				</v-btn>
			</v-snackbar>
			<!-- Commessa saving Confirmation dialog -->
			<v-dialog content-class="artDialog" persistent v-model="dialogSalvataggio" width="400">
				<v-card>
					<v-toolbar dense class="indigo">
						<v-toolbar-title class="white--text">Salvataggio</v-toolbar-title>
					</v-toolbar>
					<v-card-text class="mt-6">
						<h3 style="text-align: left !important">
							Sei sicuro di voler salvare la commessa?
						</h3>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn
							color="error"
							dark
							@click="
								dialogSalvataggio = false;
								this.overlay = false;
							"
							>ANNULLA</v-btn
						>
						<v-btn color="success" dark @click="SalvaCommessa">CONFERMA</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
			<!-- Search new Article dialog -->
			<v-dialog persistent v-model="cod_articolo_dialog" width="unset">
				<v-overlay :value="overlay" style="z-index: 10">
					<v-progress-circular indeterminate size="64"></v-progress-circular>
				</v-overlay>
				<v-card>
					<v-toolbar color="indigo" dense>
						<v-toolbar-title class="white--text">
							Cod. Articolo Nuova Commessa
						</v-toolbar-title>
						<v-spacer></v-spacer>
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-btn v-on="on" v-bind="attrs" class="ml-3" @click="goBack" small>
									<v-icon color="red">mdi-keyboard-backspace</v-icon>
								</v-btn>
							</template>
							<span>Indietro</span>
						</v-tooltip>
					</v-toolbar>
					<v-card-text>
						<v-row justify="center" align="center">
							<v-col cols="4">
								Inserisci il codice articolo per la nuova commessa:
							</v-col>
							<v-col cols="4">
								<v-text-field
									v-model="cod_articolo"
									label="Cod. Articolo"
									placeholder="Cod. Articolo"
								></v-text-field>
							</v-col>
							<v-col cols="4">
								<v-tooltip bottom>
									<template v-slot:activator="{ on, attrs }">
										<v-btn v-on="on" v-bind="attrs" class="mr-5" @click="goToArticolo" small>
											<v-icon color="success">mdi-check</v-icon>
										</v-btn>
									</template>
									<span>Vai all'Articolo</span>
								</v-tooltip>
								<v-tooltip bottom>
									<template v-slot:activator="{ on, attrs }">
										<v-btn v-on="on" v-bind="attrs" @click="searchResult" small>
											<v-icon color="primary">mdi-magnify</v-icon>
										</v-btn>
									</template>
									<span>Cerca</span>
								</v-tooltip>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-dialog>
			<!-- Allegati dialog -->
			<v-dialog persistent :hide-overlay="true" v-model="dialogAllegati" width="900">
				<v-card>
					<v-toolbar dense primary-title>
						<v-toolbar-title class="white--text">Allegati Commessa</v-toolbar-title>
						<v-spacer></v-spacer>
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-btn @click="dialogAllegati = false" small>
									<v-icon v-on="on" v-bind="attrs" depressed class="" color="error"
										>mdi-window-close</v-icon
									>
								</v-btn>
							</template>
							<span>Cancellare </span>
						</v-tooltip>
					</v-toolbar>
					<div v-viewer class="images clearfix">
						<img
							:src="this.immagine_preview"
							class="white--text image align-end"
							gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
						/>
					</div>
					<v-card-text> </v-card-text>
				</v-card>
			</v-dialog>
			<!-- To add new Allegati -->
			<v-dialog persistent :hide-overlay="true" v-model="dialogAggiungiAllegati" width="500">
				<v-card>
					<v-toolbar dense class="indigo darken-1" primary-title>
						<v-toolbar-title class="white--text">Aggiungi Commessa Allegati</v-toolbar-title>
						<v-spacer></v-spacer>
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-btn small @click="dialogAggiungiAllegati = false">
									<v-icon v-on="on" v-bind="attrs" depressed class="" color="error"
										>mdi-window-close</v-icon
									>
								</v-btn>
							</template>
							<span>Cancellare </span>
						</v-tooltip>
					</v-toolbar>
					<v-card-text>
						<v-text-field
							v-model="immagine_preview"
							@paste="onPaste"
							accept="image/*"
							label="Immagine"
							:placeholder="immagine_preview"
							prepend-icon="mdi-camera"
							@click:prepend="openImagePicker"
						></v-text-field>
						<input type="file" v-on:change="onFileChange" ref="allegato" name="allegato" class="d-none" />
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-tooltip right>
							<template v-slot:activator="{ on, attrs }">
								<v-btn @click="setImmagine()">
									<v-icon v-on="on" color="#009900" v-bind="attrs"> mdi-floppy </v-icon>
								</v-btn>
							</template>
							<span>Salva</span>
						</v-tooltip>
					</v-card-actions>
				</v-card>
			</v-dialog>
			<!-- Articolo Results -->
			<v-dialog
				v-model="dialogListaArticoli"
				content-class="artDialog"
				:scrollable="false"
				persistent
				width="unset"
			>
				<v-card>
					<v-toolbar dense class="indigo darken-1 headline" primary-title>
						<v-toolbar-title class="white--text">Articolo</v-toolbar-title>
						<v-spacer></v-spacer>
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-btn v-bind="attrs" v-on="on" @click="dialogListaArticoli = false" small>
									<v-icon color="error"> mdi-window-close</v-icon>
								</v-btn>
							</template>
							<span>Chiudi</span>
						</v-tooltip>
					</v-toolbar>
					<v-form>
						<v-container>
							<v-row class="mx-auto ml-auto">
								<v-col class="ml-auto" sm="8" md="3">
									<v-text-field
										class="shrink"
										v-model="cod_articolo"
										label="Cod Articolo"
										required
									></v-text-field>
								</v-col>
								<v-col class="mr-auto" sm="3" md="1">
									<v-tooltip bottom>
										<template v-slot:activator="{ on, attrs }">
											<v-btn small>
												<v-icon v-on="on" class v-bind="attrs" size="32" color="#1a80b6"
													>mdi-magnify</v-icon
												>
											</v-btn>
										</template>
										<span>Cerca Articolo</span>
									</v-tooltip>
								</v-col>
								<v-col sm="2" xs="12" md="2">
									<v-autocomplete
										v-model="id_tipo"
										:items="this.tipoList"
										label="Tipo Articolo"
										item-text="des"
										required
										return-object
									>
										<template v-slot:selection="data">
											<v-chip
												color="light-blue lighten-4"
												small
												class="mb-0 mt-1"
												v-bind="data.attrs"
												:input-value="data.selected"
												close
												@click="data.select"
												@click:close="tiporemove(data.item)"
												>{{ data.item.des }}</v-chip
											>
										</template>
									</v-autocomplete>
								</v-col>
								<v-col cols sm="4" xs="12" md="3">
									<v-autocomplete
										v-model="id_famiglia"
										:items="famiglia_list"
										item-text="des"
										required
										return-object
										label="Tipo Famiglia"
									>
										<template v-slot:selection="data">
											<v-chip
												color="light-blue lighten-4"
												small
												v-bind="data.attrs"
												:input-value="data.selected"
												close
												@click="data.select"
												class="mb-0 mt-1"
												@click:close="remove(data.item)"
												>{{ data.item.des }}</v-chip
											>
										</template>
									</v-autocomplete>
								</v-col>
								<v-col class="mr-auto" cols sm="4" xs="12" md="3"> </v-col>
							</v-row>
						</v-container>
					</v-form>
					<v-card-text style="max-height: 600px; overflow-y: auto">
						<v-row>
							<v-col md="12">
								<v-data-table
									:footer-props="{ 'items-per-page-options': [100, -1] }"
									:search="search"
									:headers="this.headers"
									:items="tableData"
								>
									<template v-slot:[`item.actions`]="props">
										<v-tooltip bottom>
											<template v-slot:activator="{ on, attrs }">
												<v-btn v-on="on" v-bind="attrs" small @click="onDetail(props.item)">
													<v-icon style="float: left" color="primary">mdi-pencil</v-icon>
												</v-btn>
											</template>
											<span>Dettaglio Articolo</span>
										</v-tooltip>
									</template>
								</v-data-table>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-dialog>
			<!-- Maschera per le commesse da generare -->
			<v-card>
				<v-toolbar primary-title dense color="indigo">
					<v-spacer></v-spacer>
					<Button
						classe_bottone="ml-2"
						colore_icona="primary"
						idForm="COMMESSA_DETTAGLIO"
						idOggetto="STAMPA_COMMESSA"
						mdi_icona="mdi-printer"
						testo_tooltip="Stampa Commessa"
						v-on:buttonClick="printCommessa"
						style="float: left !important"
					/>
					<!-- <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ml-2"
                @click="dialogAggiungiAllegati = true"
                small
                style="float: left !important"
              >
                <v-icon v-on="on" v-bind="attrs" color="#1a80b6"
                  >mdi-paperclip</v-icon
                >
              </v-btn>
            </template>
            <span> Aggiungi Allegati</span>
          </v-tooltip> -->
					<Button

						classe_bottone="ml-2"
						colore_icona="success"
						idForm="COMMESSA_DETTAGLIO"
						idOggetto="SAlVA_COMMESSA"
						mdi_icona="mdi-floppy"
						testo_tooltip="Salva Commessa"
						v-on:buttonClick="dialogSalvataggio = true"
						style="float: left !important"
					/>

						<Button
						classe_bottone="ml-2"
						colore_icona="success"
						idForm="COMMESSA_DETTAGLIO"
						idOggetto="PART_PROGRAM_MACCHINA_COMMESSA"
						mdi_icona="mdi-cogs"
						testo_tooltip="Part Program"

					/>
					<v-spacer></v-spacer>
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn style="float: left !important" @click="goBack" small>
								<v-icon v-on="on" v-bind="attrs" color="red">mdi-keyboard-backspace</v-icon>
							</v-btn>
						</template>
						<span>Indietro</span>
					</v-tooltip>
				</v-toolbar>
				<form class="px-5" v-if="id_commessa == -1">
					<v-container>
						<v-row>
							<v-col cols="3" justify="center" class="py-0">
								<TextField
									idForm="COMMESSA"
									idOggetto="CREA_CODICE_COMMESSA"
									label_input="Codice Commessa"
									palceholder_input="Codice Commessa"
									:readonly="true"
									:vmodel="cod_commessa"
									v-on:changeText="(val) => (this.cod_commessa = val)"
								/>
							</v-col>
							<v-col cols="3" justify="center" class="py-0">
								<v-menu
									v-model="menu2"
									:close-on-content-click="false"
									:nudge-right="40"
									transition="scale-transition"
									offset-y
								>
									<template v-slot:activator="{ on }">
										<v-text-field
											:value="formatdatacommessa"
											label="Data Commessa"
											prepend-icon="event"
											readonly
											v-on="on"
										></v-text-field>
									</template>
									<v-date-picker
										style="width: 100% !important"
										v-model="data_commessa"
										@input="menu2 = false"
									></v-date-picker>
								</v-menu>
							</v-col>
							<v-col cols="3" justify="center" class="py-0">
								<v-menu
									v-model="menu3"
									:close-on-content-click="false"
									:nudge-right="40"
									transition="scale-transition"
									offset-y
								>
									<template v-slot:activator="{ on }">
										<v-text-field
											:value="formatdataconsegna"
											label="Data Consegna"
											prepend-icon="event"
											readonly
											v-on="on"
										></v-text-field>
									</template>
									<v-date-picker
										style="width: 100% !important"
										v-model="data_consegna"
										@input="menu3 = false"
									></v-date-picker>
								</v-menu>
							</v-col>
							<v-col cols="3" justify="center" class="py-0">
								<v-menu
									v-model="menu5"
									:close-on-content-click="false"
									:nudge-right="40"
									transition="scale-transition"
									offset-y
								>
									<template v-slot:activator="{ on }">
										<v-text-field
											:value="format_data_spedizione"
											label="Data Spedizione"
											prepend-icon="event"
											readonly
											v-on="on"
										></v-text-field>
									</template>
									<v-date-picker
										style="width: 100% !important"
										v-model="data_spedizione"
										@input="menu5 = false"
									></v-date-picker>
								</v-menu>
							</v-col>
							<v-col cols="3" md="3" justify="center" class="py-0">
								<TextField
									idForm="COMMESSA"
									idOggetto="CM_DES_COMMESSA"
									label_input="Descrizione Commessa"
									palceholder_input="Descrizione Commessa"
									:vmodel="des_commessa"
									v-on:changeText="(val) => (this.des_commessa = val)"
								/>
							</v-col>
							<v-col cols="3" md="3" justify="center" class="py-0">
								<v-autocomplete
									v-model="des_stato"
									:items="stato_list"
									item-text="des"
									label="Stato"
									placeholder="Stato"
									required
									item-value="des"
								>
								</v-autocomplete>
							</v-col>
							<v-col cols="5" md="6" justify="center" class="py-0">
								<v-text-field
									style="width: 500px !important"
									v-model="cod_articolo"
									placeholder="Codice Articolo"
									label="codice Articolo"
									v-on:keydown.enter.prevent="searchResult"
								>
									<template v-slot:append>
										<v-icon @click="searchResult" style="color: #03a9f4" class="ml-2"
											>mdi-magnify</v-icon
										>
									</template>
								</v-text-field>
							</v-col>
							<v-col cols="3" md="3" justify="center" class="py-0">
								<TextField
									idForm="COMMESSA"
									idOggetto="CM_DES_ARTICOLO"
									label_input="Des. Articolo"
									palceholder_input="Des. Articolo"
									:vmodel="des_articolo"
									v-on:changeText="(val) => (this.des_articolo = val)"
								/>
							</v-col>
							<v-col cols="3" md="3" justify="center" class="py-0">
								<v-autocomplete
									v-model="id_tipo"
									:items="tipoList"
									placeholder="Tipo Articolo"
									label="Tipo Articolo"
									item-text="des"
									required
									item-value="id_tabella"
								></v-autocomplete>
							</v-col>
							<v-col cols="3" md="3" justify="center" class="py-0">
								<v-autocomplete
									v-model="id_famiglia"
									:items="famiglia_list"
									placeholder="Famiglia"
									label="Famiglia"
									item-text="des"
									required
									item-value="id_tabella"
								></v-autocomplete>
							</v-col>
							<v-col cols="2" md="3" justify="center" class="py-0">
								<v-autocomplete
									style="width: 350px !important"
									v-model="id_tipo_fornitura"
									:items="fornitura_list"
									placeholder="Fornitura"
									label="Fornitura"
									item-text="des"
									required
									item-value="id_tabella"
								></v-autocomplete>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="2" justify="center" class="py-0" id="listener_quantita">
								<InputSeparatore
									:destinazione="'listener_quantita'"
									:label="'Quantità'"
									:id="'inputQuantita'"
								/>
							</v-col>
							<v-col cols="2" justify="center" class="py-0">
								<TextField
									idForm="COMMESSA"
									idOggetto="CM_COD_LOTTO"
									label_input="Lotto"
									palceholder_input="Lotto"
									:vmodel="cod_lotto"
									v-on:changeText="(val) => (this.cod_lotto = val)"
									:min_input="0"
									type_input="number"
								/>
							</v-col>
							<v-col cols="2" justify="center" class="py-0">
								<TextField
									idForm="COMMESSA"
									idOggetto="CM_INDICE_DISEGNO"
									label_input="Indice"
									palceholder_input="Indice"
									:vmodel="indice_disegno"
									v-on:changeText="(val) => (this.indice_disegno = val)"
								/>
							</v-col>
							<v-col cols="3" justify="center" class="py-0">
								<v-menu
									v-model="menu4"
									:close-on-content-click="false"
									:nudge-right="40"
									transition="scale-transition"
									offset-y
								>
									<template v-slot:activator="{ on }">
										<v-text-field
											:value="formatdatadum"
											label="D.U.M"
											prepend-icon="event"
											readonly
											v-on="on"
										></v-text-field>
									</template>
									<v-date-picker
										style="width: 100% !important"
										v-model="dum"
										@input="menu4 = false"
									></v-date-picker>
								</v-menu>
							</v-col>
							<v-col cols="3" justify="center" class="py-0">
								<TextField
									idForm="COMMESSA"
									idOggetto="CM_RIFERIMENTO"
									label_input="Riferimento"
									palceholder_input="Riferimento"
									:vmodel="riferimento"
									v-on:changeText="(val) => (this.riferimento = val)"
								/>
							</v-col>
						</v-row>
					</v-container>
				</form>
				<!-- Maschera per le commesse già generate -->
				<form class="px-5" v-if="id_commessa > 0">
					<v-container>
						<v-row style="font-size: 17px">
							<v-col class="py-0" md="2" cols="2" justify="center">
								<!--<b>Cod. Commessa:</b> {{ cod_commessa }}-->
								<TextField
									idForm="COMMESSA"
									idOggetto="MODIFICA_CODICE_COMMESSA"
									label_input="Codice Commessa"
									palceholder_input="Codice Commessa"
									:readonly="true"
									stile="outline: none;"
									:vmodel="cod_commessa"
									v-on:changeText="(val) => (this.cod_commessa = val)"
								/>
							</v-col>
							<v-col class="py-0" md="3" cols="2" justify="center">
								<TextField
									idForm="COMMESSA"
									idOggetto="MODIFICA_DES_COMMESSA"
									label_input="Des. Commessa"
									palceholder_input="Des. Commessa"
									:vmodel="des_commessa"
									v-on:changeText="(val) => (this.des_commessa = val)"
								/>
							</v-col>
							<v-col class="py-0" cols="4" md="2" justify="center">
								<v-menu
									v-model="menu2"
									:close-on-content-click="false"
									:nudge-right="40"
									transition="scale-transition"
									offset-y
								>
									<template v-slot:activator="{ on }">
										<v-text-field
											:value="formatdatacommessa"
											label="Data Commessa"
											prepend-icon="event"
											readonly
											v-on="on"
										></v-text-field>
									</template>
									<v-date-picker
										style="width: 100% !important"
										v-model="data_commessa"
										@input="menu2 = false"
									></v-date-picker>
								</v-menu>
							</v-col>
							<v-col class="py-0" cols="4" md="2" justify="center">
								<v-menu
									v-model="menu3"
									:close-on-content-click="false"
									:nudge-right="40"
									transition="scale-transition"
									offset-y
								>
									<template v-slot:activator="{ on }">
										<v-text-field
											:value="formatdataconsegna"
											label="Data Consegna"
											prepend-icon="event"
											readonly
											v-on="on"
										></v-text-field>
									</template>
									<v-date-picker
										style="width: 100% !important"
										v-model="data_consegna"
										@input="menu3 = false"
									></v-date-picker>
								</v-menu>
							</v-col>
							<v-col cols="3" justify="center" class="py-0">
								<v-menu
									v-model="menu5"
									:close-on-content-click="false"
									:nudge-right="40"
									transition="scale-transition"
									offset-y
								>
									<template v-slot:activator="{ on }">
										<v-text-field
											:value="format_data_spedizione"
											label="Data Spedizione"
											prepend-icon="event"
											readonly
											v-on="on"
										></v-text-field>
									</template>
									<v-date-picker
										style="width: 100% !important"
										v-model="data_spedizione"
										@input="menu5 = false"
									></v-date-picker>
								</v-menu>
							</v-col>
							<v-col class="py-0" cols="2" justify="center">
								<v-autocomplete
									label="Stato"
									placeholder="Stato"
									item-text="des"
									v-model="des_stato"
									:items="stato_list"
									item-value="des"
								></v-autocomplete>
							</v-col>
							<v-col class="py-0" cols="2" justify="center">
								<TextField
									idForm="COMMESSA"
									:readonly="true"
									idOggetto="MODIFICA_COD_ARTICOLO"
									label_input="Cod. Articolo"
									palceholder_input="Cod. Articolo"
									:vmodel="cod_articolo"
									v-on:changeText="(val) => (this.cod_articolo = val)"
								/>
							</v-col>
							<v-col class="py-0" cols="3">
								<TextField
									idForm="COMMESSA"
									idOggetto="MODIFICA_DES_ARTICOLO"
									label_input="Descrizione"
									palceholder_input="Descrizione"
									:vmodel="des_articolo"
									v-on:changeText="(val) => (this.des_articolo = val)"
								/>
							</v-col>
							<v-col class="py-0" cols="2">
								<TextField
									idForm="COMMESSA"
									idOggetto="MODIFICA_TIPO_ARTICOLO"
									label_input="Tipo"
									palceholder_input="Tipo"
									:vmodel="tipo_articolo"
									:readonly="true"
								/>
							</v-col>
							<v-col class="py-0" cols="3" justify="center">
								<TextField
									idForm="COMMESSA"
									idOggetto="MODIFICA_FAMIGLIA_ARTICOLO"
									label_input="Famiglia"
									palceholder_input="Famiglia"
									:vmodel="famiglia_articolo"
									:readonly="true"
								/>
							</v-col>
							<v-col class="py-0" cols="3" justify="center">
								<TextField
									idForm="COMMESSA"
									idOggetto="MODIFICA_FORNITURA_ARTICOLO"
									label_input="Fornitura"
									palceholder_input="Fornitura"
									:vmodel="fornitura_articolo"
									:readonly="true"
								/>
							</v-col>
							<v-col class="py-0" cols="2" justify="center" id="listener_quantita">
								<InputSeparatore
									:destinazione="'listener_quantita'"
									:label="'Quantità'"
									:id="'inputQuantita'"
								/>
							</v-col>
							<v-col class="py-0" cols="2" justify="center">
								<TextField
									idForm="COMMESSA"
									idOggetto="MODIFICA_DUM_COMMESSA"
									label_input="D.U.M"
									palceholder_input="D.U.M"
									:vmodel="dum"
									:readonly="true"
								/>
							</v-col>
							<v-col class="py-0" cols="2" justify="center">
								<TextField
									idForm="COMMESSA"
									idOggetto="MODIFICA_INDICE_DISEGNO"
									label_input="Indice"
									palceholder_input="Indice"
									:vmodel="indice_disegno"
									:readonly="true"
								/>
							</v-col>
							<v-col class="py-0" cols="2" justify="center">
								<v-btn class="mt-3" @click="clickVisualizzaDisegno()" style="z-index: 1">
									<v-icon color="primary"> mdi-eye </v-icon>
								</v-btn>
							</v-col>
							<v-col class="py-0" cols="2" justify="center">
								<TextField
									idForm="COMMESSA"
									idOggetto="MODIFICA_COD_LOTTO"
									label_input="Lotto"
									palceholder_input="Lotto"
									:vmodel="cod_lotto"
									:readonly="true"
								/>
							</v-col>
							<v-col class="py-0" cols="5" justify="center">
								<TextField
									idForm="COMMESSA"
									idOggetto="MODIFICA_RIFERIMENTO"
									label_input="Riferimento"
									palceholder_input="Riferimento"
									:vmodel="riferimento"
								/>
							</v-col>
						</v-row>
					</v-container>
				</form>
			</v-card>
			<!-- Children injection -->
			<CommesaDrawer
				@DelCiclo="updateCicliList($event)"
				@delMacchina="delMacchina($event)"
				@delPersonale="delPersonale($event)"
				@delFornitura="delFornitura($event)"
				@delDistinta="delDistinta($event)"
				@deleteAllFasi="deleteAllFasi($event)"
				@updateDrawer="updateArticle($event)"
				:idArticolo="this.id_articolo"
				:key="this.CommessaData"
				:CicloData="this.CommessaData"
			/>
			<!-- To see Attached disegno -->
			<v-dialog persistent v-model="visualizza_disegno_visible">
				<VisualizzaDisegno
					@click_chiudi="visualizza_disegno_visible = !visualizza_disegno_visible"
					:visible="visualizza_disegno_visible"
					:work_order_sel="work_order_sel"
				/>
			</v-dialog>
			<!-- Gestione dialog Stampa Cartellino -->
			<v-dialog v-model="pdfdialog" width="unset" content-class="pdfdialog">
				<v-card>
					<v-toolbar class="white--text" color="blue" dense style="position: sticky; top: 0px; z-index: 1">
						<v-toolbar-title> Stampa Pdf </v-toolbar-title>
						<v-spacer></v-spacer>
						<v-btn class="red--text" @click="pdfdialog = false" color="blue-grey lighten-5" small>
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</v-toolbar>
					<v-row>
						<v-col cols="12" sm="6" md="12">
							<iframe
								:src="selected_pdf"
								frameborder="0"
								style="height: 90vh !important; width: 90vw !important"
							>
							</iframe>
						</v-col>
					</v-row>
				</v-card>
			</v-dialog>
			<!--Loader  -->
			<v-overlay :value="overlay" style="z-index: 1">
				<v-progress-circular indeterminate size="64"></v-progress-circular>
			</v-overlay>
		</v-card>
	</div>
</template>
<script>
// Inputseparatore generic input to show value in thousands
import Button from '../../../components/TOOLS/FormComponentPermissions/Button.vue';
import InputSeparatore from '@/components/TOOLS/InputSeparatore.vue';
import TextField from '@/components/TOOLS/FormComponentPermissions/TextField.vue';
import CommesaDrawer from './CommesaDrawer';
import VisualizzaDisegno from '@/components/PRODUCTION/ProduzioneOnline/VisualizzaDisegno.vue';
import moment from 'moment';
export default {
	name: 'commessa',
	components: {
		CommesaDrawer,
		InputSeparatore,
		TextField,
		VisualizzaDisegno,
		Button,
	},
	data() {
		return {
			// Data Spedizione Field
			menu5: false,
			data_spedizione: '',
			// riferimento
			riferimento: '',
			// DeletedPersonale
			deletedPersonale: [],
			deletedMacchina: [],
			deletedFornitura: [],
			deletedDistinta: [],
			deleteAllFasiLista: [],
			// snackbar for quantita
			dialogSalvataggio: false,
			deletedCicli: [],
			// Variabili d'appoggio
			work_order_sel: { id_articolo: null },
			// Modal
			snackbar: false,
			snackbar_background: '',
			snackbar_icon: '',
			snackbar_icon_color: '',
			snackbar_text: '',
			snackbar_text_color: '',
			visualizza_disegno_visible: false,
			// Array
			des: '',
			CommessaData: [],
			data: {
				commessa: [],
			},
			cod_articolo_dialog: false,
			search: '',
			cod_articolo: '',
			headers: [],
			tableData: [],
			cicloData: [],
			id_articolo: null,
			id_commessa: '',
			singleCommessa: '',
			immagine_preview: '',
			data_commessa: '',
			data_consegna: '',
			dialogAllegati: false,
			dialogListaArticoli: false,
			dialogAggiungiAllegati: false,
			des_stato: '',
			cod_commessa: '',
			des_commessa: '',
			id_stato: '',
			quantita: 0,
			cod_lotto: '',
			indice_disegno: '',
			dum: '',
			des_articolo: '',
			barcode: '',
			tipo_articolo: '',
			id_tipo_fornitura: '',
			famiglia_articolo: '',
			fornitura_articolo: '',
			menu2: false,
			menu3: false,
			menu4: false,
			famiglia_list: [],
			tipoList: [],
			itemList: [],
			tipo_fornitura: '',
			fornitura_list: [],
			des_stato_list: [],
			stato_list: [],
			id_tipo: 0,
			id_famiglia: 0,
			overlay: false,
			pdfdialog: false,
			selected_pdf: null,
		};
	},
	methods: {

		// In order to delete all fasi
		deleteAllFasi(ev) {
			console.log('this.deleteAllFasiLista :>> ', this.deleteAllFasiLista);
			this.deleteAllFasiLista = ev;
		},
		// In order to Update the deleted Distinta base
		delDistinta(event) {
			this.deletedDistinta.push(event);
		},
		// Check if cicli list is not empty
		updateCicliList(event) {
			this.deletedCicli.push(event[0]);
		},
		delMacchina(event) {
			if (event.length > 0) {
				this.deletedMacchina = event;
			}
		},
		delPersonale(event) {
			if (event.length > 0) {
				this.deletedPersonale = event;
			}
		},
		delFornitura(event) {
			if (event.length > 0) {
				this.deletedFornitura = event;
			}
		},
		goToArticolo() {
			if (this.cod_articolo === '') {
				return;
			}
			this.overlay = true;
			let request = {
				controller: 'Magazzino',
				method: 'POST',
				richiesta: {
					action: 'getarticoli',
					token: localStorage.getItem('user_token'),
					cod_articolo: this.cod_articolo,
					id_famiglia: this.id_famiglia.id_tabella == undefined ? '' : this.id_famiglia.id_tabella,
					id_tipo: this.id_tipo.id_tabella == undefined ? '' : this.id_tipo.id_tabella,
				},
			};
			this.$store.dispatch('api', request).then((res) => {
				if (res.status === 500) {
					this.overlay = false;
				}
				this.completeData = res.data;
				this.overlay = false;
				if (this.completeData.lista.length > 1) {
					this.snackbar = true;
					this.snackbar_background = 'success';
					this.snackbar_icon = 'mdi-check';
					this.snackbar_icon_color = 'white';
					this.snackbar_text = 'Più articoli corrispondono a questo codice';
					this.snackbar_text_color = 'white';
				}
				if (this.completeData.lista.length === 0) {
					this.snackbar = true;
					this.snackbar_background = 'error';
					this.snackbar_icon = 'mdi-close';
					this.snackbar_icon_color = 'white';
					this.snackbar_text = 'Nessun articolo trovato con questo codice.';
					this.snackbar_text_color = 'white';
				}
				if (this.completeData.lista.length === 1) {
					this.cod_articolo = this.completeData.lista[0].cod_articolo;
					let Getnewcommesa = {
						controller: 'Magazzino',
						method: 'POST',
						richiesta: {
							action: 'getnewcommessa',
							token: localStorage.getItem('user_token'),
							id_profilo: '3',
							id_articolo: this.completeData.lista[0].id_articolo,
						},
					};
					this.$store.dispatch('api', Getnewcommesa).then((res) => {
						this.overlay = false;
						this.CommessaData = res.data;
						if (!this.CommessaData.commessa_dettaglio) {
							this.CommessaData.commessa_dettaglio = [];
						}
						this.cod_articolo = this.CommessaData.commessa[0].cod_articolo;
						this.cod_commessa = this.CommessaData.commessa[0].cod_commessa;
						this.des_commessa = this.CommessaData.commessa[0].des_commessa;
						this.data_commessa = this.CommessaData.commessa[0].data_commessa;
						this.des_stato = 'Creata';
						this.des_articolo = this.CommessaData.commessa[0].des_articolo;
						this.data_consegna = this.CommessaData.commessa[0].data_consegna;
						this.id_famiglia = this.CommessaData.commessa[0].id_famiglia;
						this.id_tipo_fornitura = this.CommessaData.commessa[0].id_tipo_fornitura;
						this.id_tipo = this.CommessaData.commessa[0].id_tipo;
						this.indice_disegno = this.CommessaData.commessa[0].indice_disegno;
						this.id_articolo = this.CommessaData.commessa[0].id_articolo;
					});
					this.cod_articolo_dialog = false;
				}
			});
			this.overlay = false;
			this.dialogListaArticoli = false;
		},
		clickVisualizzaDisegno() {
			this.visualizza_disegno_visible = true;
			this.work_order_sel.id_articolo = this.id_articolo;
		},
		updateArticle(val) {
			val[0].children.map((va) => (va.children = []));
			this.CommessaData.commessa_distinta = val[0].children;
		},
		// IN order to go back to commessa main page
		goBack() {
			this.$router.push({
				name: 'commessa',
				params: { foo: 1 },
			});
		},
		// In order to Json to create new commessa
		onDetail(item) {
			this.id_articolo = item.id_articolo;
			this.cod_articolo = item.cod_articolo;
			this.cod_articolo_dialog = false;
			this.dialogListaArticoli = false;
			let Getnewcommesa = {
				controller: 'Magazzino',
				method: 'POST',
				richiesta: {
					action: 'getnewcommessa',
					token: localStorage.getItem('user_token'),
					id_profilo: '3',
					id_articolo: item.id_articolo,
				},
			};
			this.$store.dispatch('api', Getnewcommesa).then((res) => {
				this.CommessaData = res.data;
				this.overlay = false;
				if (!this.CommessaData.commessa_dettaglio) {
					this.CommessaData.commessa_dettaglio = [];
				}
				this.data_commessa = this.CommessaData.commessa[0].data_commessa;
				this.des_articolo = this.CommessaData.commessa[0].des_articolo;
				this.des_stato = 'Creata';
				this.stato_list.map((it) => {
					if (it.id_tabella === this.CommessaData.commessa[0].id_stato) {
						this.des_stato = it.des;
					}
				});
				this.id_famiglia = this.CommessaData.commessa[0].famiglia_articolo;
				this.id_tipo_fornitura = this.CommessaData.commessa[0].fornitura_articolo;
				this.id_tipo = this.CommessaData.commessa[0].tipo_articolo;
				this.indice_disegno = this.CommessaData.commessa[0].indice_disegno;
				this.id_articolo = this.CommessaData.commessa[0].id_articolo;
			});
		},
		// IN order to search in search filed to get json to create new commessa
		searchResult() {
			if (this.cod_articolo === '') {
				return;
			}
			this.overlay = true;
			let request = {
				controller: 'Magazzino',
				method: 'POST',
				richiesta: {
					action: 'getarticoli',
					token: localStorage.getItem('user_token'),
					cod_articolo: this.cod_articolo,
					id_famiglia: this.id_famiglia.id_tabella == undefined ? '' : this.id_famiglia.id_tabella,
					id_tipo: this.id_tipo.id_tabella == undefined ? '' : this.id_tipo.id_tabella,
				},
			};
			this.$store
				.dispatch('api', request)
				.then((res) => {
					this.overlay = false;
					if (res.status === 500) {
						this.snackbar = true;
						this.snackbar_background = 'error';
						this.snackbar_icon = 'mdi-close';
						this.snackbar_icon_color = 'white';
						this.snackbar_text = 'Nessun articolo trovato con questo codice.';
						this.snackbar_text_color = 'white';
						this.overlay = false;
					}
					this.completeData = res.data;
					if (this.completeData.lista.length > 1) {
						this.tableData = this.completeData.lista;
						this.dialogListaArticoli = true;
					}
					if (this.completeData.lista.length === 0) {
						this.snackbar = true;
						this.snackbar_background = 'error';
						this.snackbar_icon = 'mdi-close';
						this.snackbar_icon_color = 'white';
						this.snackbar_text = 'Nessun articolo trovato per questo codice';
						this.snackbar_text_color = 'white';
					}
					if (this.completeData.lista.length === 1) {
						this.snackbar = true;
						this.snackbar_background = 'success';
						this.snackbar_icon = 'mdi-check';
						this.snackbar_icon_color = 'white';
						this.snackbar_text = 'È presente un articolo con questo codice.';
						this.snackbar_text_color = 'white';
					}
				})
				.catch((err) => {
					if (err) {
						this.snackbar = true;
						this.snackbar_background = 'error';
						this.snackbar_icon = 'mdi-close';
						this.snackbar_icon_color = 'white';
						this.snackbar_text = 'Errore.';
						this.snackbar_text_color = 'white';
						this.overlay = false;
					}
				});
			this.dialogListaArticoli = false;
		},
		retrieveImageFromClipboardAsBase64(pasteEvent, callback, imageFormat) {
			if (pasteEvent.clipboardData == false) {
				if (typeof callback == 'function') {
					callback(undefined);
				}
			}
			var items = pasteEvent.clipboardData.items;
			if (items == undefined) {
				if (typeof callback == 'function') {
					callback(undefined);
				}
			}
			for (var i = 0; i < items.length; i++) {
				// Skip content if not image
				if (items[i].type.indexOf('image') == -1) continue;
				// Retrieve image on clipboard as blob
				var blob = items[i].getAsFile();
				// Create an abstract canvas and get context
				var mycanvas = document.createElement('canvas');
				var ctx = mycanvas.getContext('2d');
				// Create an image
				var img = new Image();
				// Once the image loads, render the img on the canvas
				img.onload = function() {
					// Update dimensions of the canvas with the dimensions of the image
					mycanvas.width = this.width;
					mycanvas.height = this.height;
					// Draw the image
					ctx.drawImage(img, 0, 0);
					// Execute callback with the base64 URI of the image
					if (typeof callback == 'function') {
						callback(mycanvas.toDataURL(imageFormat || 'image/png'));
					}
				};
				// Crossbrowser support for URL
				var URLObj = window.URL || window.webkitURL;
				// Creates a DOMString containing a URL representing the object given in the parameter
				// namely the original Blob
				img.src = URLObj.createObjectURL(blob);
			}
		},
		onPaste(e) {
			if (e.clipboardData != false) {
				var items = e.clipboardData.items;
				if (items != undefined) {
					for (var i = 0; i < items.length; i++) {
						// Skip content if not image
						if (items[i].type.indexOf('image') == -1) continue;
					}
				}
			}
			this.retrieveImageFromClipboardAsBase64(e, function(imageDataBase64) {
				this.articoloDetail.immagine_preview = imageDataBase64;
			});
		},
		openImagePicker() {
			this.$refs.allegato.click();
		},
		onFileChange(e) {
			var files = e.target.files || e.dataTransfer.files;
			if (!files.length) return;
			var imagetoBase64 = files[0];
			var dataBase64;
			var reader = new FileReader();
			reader.readAsDataURL(imagetoBase64);
			reader.onload = function() {
				dataBase64 = reader.result;
				this.immagine_preview = dataBase64;
				localStorage.setItem('ArticoloImage', this.immagine_preview);
			};
		},
		setImmagine() {
			this.immagine_preview = localStorage.getItem('ArticoloImage');
			this.dialogAggiungiAllegati = false;
		},
		// In order to save a new commessa or Update an existing one.
		SalvaCommessa() {
			this.dialogSalvataggio = false;

			if (this.quantita == 0) {
				this.snackbar = true;
				this.snackbar_background = 'orange';
				this.snackbar_icon = 'mdi-alert';
				this.snackbar_icon_color = 'white';
				this.snackbar_text = ' La quantità deve essere maggiore di 0';
				this.snackbar_text_color = 'white';
				return;
			}
			if (this.CommessaData.commessa[0].id_commessa === -1) {
				if (this.deletedCicli.length > 0) {
					this.deletedCicli.map((it) => {
						this.CommessaData.commessa_dettaglio.push(it);
					});
				}
				this.CommessaData.commessa[0].data_commessa = this.data_commessa;
				this.CommessaData.commessa[0].data_consegna = this.data_consegna;
				this.CommessaData.commessa[0].cod_commessa = this.cod_commessa;
				this.CommessaData.commessa[0].quantita = this.quantita;
				this.CommessaData.commessa[0].des_commessa = this.des_commessa;
				this.CommessaData.commessa[0].des_articolo = this.des_articolo;
				this.CommessaData.commessa[0].barcode = null;
				this.CommessaData.commessa[0].riferimento = this.riferimento;
				this.CommessaData.commessa[0].dum = this.dum;
				this.CommessaData.commessa[0].indice_disegno = this.indice_disegno;
				this.CommessaData.commessa[0].cod_lotto = this.cod_lotto;
				this.CommessaData.commessa[0].id_stato = this.des_stato;
				this.CommessaData.commessa[0].data_spedizione = this.data_spedizione;
				this.stato_list.map((de) => {
					if (de.des === this.des_stato) {
						this.CommessaData.commessa[0].des_stato = de.des;
						this.CommessaData.commessa[0].id_stato = de.id_tabella;
					}
				});
				this.CommessaData.commessa[0].cod_articolo = this.cod_articolo;
				this.tipoList.map((ti) => {
					if (this.id_tipo === ti.des) {
						this.CommessaData.commessa[0].id_tipo = ti.id_tabella;
					}
				});
				this.famiglia_list.map((fi) => {
					if (fi.des === this.famiglia_articolo) {
						this.CommessaData.commessa[0].id_famiglia = fi.id_tabella;
					}
				});
				this.fornitura_list.map((forni) => {
					if (forni.des === this.fornitura_articolo) {
						this.CommessaData.commessa[0].id_tipo_fornitura = forni.id_tabella;
					}
				});
				this.CommessaData.commessa_distinta.map((it) => (it.children = []));
					this.overlay = true;
				let setcommessa = {
					controller: 'Magazzino',
					method: 'PUT',
					richiesta: {
						action: 'setcommessa',
						token: localStorage.getItem('user_token'),
						id_commessa: '-1',
						id_articolo: this.id_articolo,
						id_profilo: localStorage.id_profilo,
						tabella: [this.CommessaData],
					},
				};
				this.$store.dispatch('api', setcommessa).then((res) => {
					if (res.status === 200) {
						this.goBack();

						this.snackbar = true;
						this.snackbar_background = 'success';
						this.snackbar_icon = 'mdi-check';
						this.snackbar_icon_color = 'white';
						this.snackbar_text = 'Modifica effettuata con successo!';
						this.snackbar_text_color = 'white';
					} else {
						this.snackbar = true;
						this.snackbar_background = 'error';
						this.snackbar_icon = 'mdi-close';
						this.snackbar_icon_color = 'white';
						this.snackbar_text = `Non è stato possibile salvare i dati: codice errore ${res.status}`;
						this.snackbar_text_color = 'white';
					}
				});
			}

			if (this.CommessaData.commessa[0].id_commessa > -1) {
				if (this.deletedCicli.length > 0) {
					this.deletedCicli.map((it) => {
						this.CommessaData.commessa_dettaglio.push(it);
					});
				}
				if (this.deleteAllFasiLista.length > 0) {
					this.deleteAllFasiLista.map((it) => {
						it.validita = 0
						this.CommessaData.commessa_dettaglio.push(it);
					});
				}
				this.CommessaData.commessa[0].cod_commessa = this.cod_commessa;
				this.CommessaData.commessa[0].des_commessa = this.des_commessa;
				this.CommessaData.commessa[0].data_commessa = this.data_commessa;
				this.CommessaData.commessa[0].data_consegna = this.data_consegna;
				this.CommessaData.commessa[0].data_spedizione = this.data_spedizione;
				this.CommessaData.commessa[0].quantita = this.quantita;
				this.CommessaData.commessa[0].riferimento = this.riferimento;
				this.stato_list.map((it) => {
					if (this.des_stato === it.des) {
						this.CommessaData.commessa[0].id_stato = it.id_tabella;
						this.CommessaData.commessa[0].des_stato = it.des;
					}
				});
				if (this.deletedPersonale.length > 0) {
					this.CommessaData.commessa_dettaglio.map((it) => {
						if (it.id_commessa_dettaglio === this.deletedPersonale[0].id_commessa_dettaglio) {
							this.deletedPersonale.map((pe) => {
								it.commessa_dettaglio_personale.push(pe);
							});
						}
					});
				}
				if (this.deletedMacchina.length > 0) {
					this.CommessaData.commessa_dettaglio.map((it) => {
						if (it.id_commessa_dettaglio === this.deletedMacchina[0].id_commessa_dettaglio) {
							this.deletedMacchina.map((pe) => {
								it.commessa_dettaglio_macchina.push(pe);
							});
						}
					});
				}
				if (this.deletedFornitura.length > 0) {
					this.CommessaData.commessa_dettaglio.map((it) => {
						if (it.id_commessa_dettaglio === this.deletedFornitura[0].id_commessa_dettaglio) {
							this.deletedFornitura.map((pe) => {
								it.commessa_dettaglio_fornitori.push(pe);
							});
						}
					});
				}
				if (this.deletedDistinta.length > 0) {
					this.deletedDistinta.map((it) => {
						this.CommessaData.commessa_distinta.push(it);
					});
				}
				this.overlay = true;
				let setcommessa = {
					controller: 'Magazzino',
					method: 'PUT',
					richiesta: {
						action: 'setcommessa',
						token: localStorage.getItem('user_token'),
						id_commessa: this.id_commessa,
						id_articolo: this.id_articolo,
						id_profilo: localStorage.id_profilo,
						tabella: [this.CommessaData],
					},
				};
				this.$store.dispatch('api', setcommessa).then((res) => {
					if (res.status === 200) {
						this.goBack();
						this.snackbar = true;
						this.snackbar_background = 'success';
						this.snackbar_icon = 'mdi-check';
						this.snackbar_icon_color = 'white';
						this.snackbar_text = 'Modifica effettuata con successo!';
						this.snackbar_text_color = 'white';
					} else {
						this.snackbar = true;
						this.snackbar_background = 'error';
						this.snackbar_icon = 'mdi-close';
						this.snackbar_icon_color = 'white';
						this.snackbar_text = `Non è stato possibile salvare i dati: codice errore ${res.status}`;
						this.snackbar_text_color = 'white';
					}
				});
			}
			this.overlay = false;
		},
		printCommessa() {
			let request = {
				controller: 'Report',
				method: 'POST',
				richiesta: {
					action: 'getreportpdf',
					token: localStorage.getItem('user_token'),
					cod_report: 'COMMESSA',
					id: this.CommessaData.commessa[0].id_commessa,

				},
			};

			this.$store.dispatch('api', request).then((res) => {
				const byteCharacters = atob(res.data.result);
				const byteNumbers = new Array(byteCharacters.length);
				for (let i = 0; i < byteCharacters.length; i++) {
					byteNumbers[i] = byteCharacters.charCodeAt(i);
				}
				const byteArray = new Uint8Array(byteNumbers);
				const blob = new Blob([byteArray], { type: 'application/pdf' });
				const blobUrl = URL.createObjectURL(blob);
				this.selected_pdf = blobUrl;
				this.pdfdialog = true;
			});
		},
	},
	computed: {
		formatdatacommessa() {
			return this.data_commessa ? moment(this.data_commessa).format('DD/MM/YYYY') : '';
		},
		formatdataconsegna() {
			return this.data_consegna ? moment(this.data_consegna).format('DD/MM/YYYY') : '';
		},
		formatdatadum() {
			return this.dum ? moment(this.dum).format('DD/MM/YYYY') : '';
		},
		format_data_spedizione() {
			return this.data_spedizione ? moment(this.data_spedizione).format('DD/MM/YYYY') : '';
		},
	},
	mounted() {
		// Aggiungo il listener per il campo di input
		this.overlay = false;
		let that = this;
		setTimeout(() => {
			document.getElementById('listener_quantita').addEventListener('nuovo_numero', function(elem) {
				that.quantita = elem.detail.numero;
			});
		}, 10);
		if (this.$route.params.id == -1) {
			this.id_commessa = -1;
			this.cod_articolo_dialog = true;
		}
		this.id_commessa = this.$route.params.id;
		this.CommessaData = this.data;
		this.cod_commessa = this.CommessaData.commessa.cod_commessa;
		this.des_commessa = this.CommessaData.commessa.des_commessa;
		this.indice_disegno = this.CommessaData.commessa.indice_disegno;
		this.id_articolo = this.CommessaData.commessa.id_articolo;
		let listaArticoli = {
			controller: 'Magazzino',
			method: 'POST',
			richiesta: {
				action: 'getheader',
				token: localStorage.getItem('user_token'),
				nome_modulo: 'lista_articoli',
			},
		};
		let dataarray = [];
		this.$store.dispatch('api', listaArticoli).then((res) => {
			dataarray = res.data;
			dataarray.map((el) => {
				this.headers.push({
					text: el.text,
					value: el.value,
				});
			});
			this.headers.splice(0, 0, {
				text: 'dettaglio',
				value: 'actions',
			});
		});
		// In order to get Saved Commessa Detail
		if (this.$route.params.id >= 1) {
			this.overlay = true;
			let getcommessa = {
				controller: 'Magazzino',
				method: 'POST',
				richiesta: {
					action: 'getcommessafull',
					token: localStorage.getItem('user_token'),
					id_commessa: this.$route.params.id,
					id_profilo: localStorage.id_profilo,
				},
			};
			this.$store.dispatch('api', getcommessa).then((res) => {
				this.overlay = false;
				this.CommessaData = res.data;
				this.singleCommessa = this.CommessaData.commessa[0];
				this.cicloData = this.CommessaData.ciclo;
				this.indice_disegno = this.singleCommessa.indice_disegno;
				this.id_stato = this.singleCommessa.id_stato;
				this.cod_lotto = this.singleCommessa.cod_lotto;
				this.id_articolo = this.singleCommessa.id_articolo;
				this.stato_list.map((de) => {
					if (de.id_tabella === this.CommessaData.commessa[0].id_stato) {
						this.des_stato = de.des;
					}
				});
				this.cod_commessa = this.CommessaData.commessa[0].cod_commessa;
				this.des_commessa = this.CommessaData.commessa[0].des_commessa;
				this.quantita = this.CommessaData.commessa[0].quantita;
				this.riferimento = this.CommessaData.commessa[0].riferimento;
				this.data_commessa = this.CommessaData.commessa[0].data_commessa;
				this.data_consegna = this.CommessaData.commessa[0].data_consegna;
				this.data_spedizione = this.CommessaData.commessa[0].data_spedizione;
				this.des_commessa = this.CommessaData.commessa[0].des_commessa;
				this.des_articolo = this.CommessaData.commessa[0].des_articolo;
				this.cod_articolo = this.CommessaData.commessa[0].cod_articolo;
				this.barcode = this.CommessaData.commessa[0].barcode;
				this.tipo_articolo = this.CommessaData.commessa[0].tipo_articolo;
				this.famiglia_articolo = this.CommessaData.commessa[0].famiglia_articolo;
				this.id_famiglia = this.CommessaData.commessa[0].id_famiglia;
				this.id_commessa = this.CommessaData.commessa[0].id_commessa;
				this.fornitura_articolo = this.CommessaData.commessa[0].fornitura_articolo;
				this.dum = moment(this.CommessaData.commessa[0].dum).format('DD/MM/YYYY');
				this.cod_lotto = this.CommessaData.commessa[0].cod_lotto;
				this.indice_disegno = this.CommessaData.commessa[0].indice_disegno;
				// Valorizzo la variabile amount con un custom event
				// Compongo un messaggio.
				let messaggio = {
					detail: {
						numero: this.quantita,
					},
					src: null,
				};
				setTimeout(() => {
					// Inserisco il messaggio in un evento custom.
					let evento = new CustomEvent('numero_iniziale', messaggio);
					// Consegno alla mia destinazione l'evento
					document.getElementById('listener_InputSeparatoreinputQuantita').dispatchEvent(evento);
				}, 10);
			});
		}
		// IN order to get List data for select fields
		let request = {
			controller: 'Magazzino',
			method: 'POST',
			richiesta: {
				action: 'gettabelle',
				token: localStorage.getItem('user_token'),
				db_name: 'quickproduction',
				tipo: "'FAMIGLIA','TIPOARTICOLO','FORNITURA_STANDARD','COMMESSA_STATO'",
				filter: 'validita = 1',
			},
		};
		this.$store.dispatch('api', request).then((res) => {
			this.famiglia_list =
				res.data.Famiglia != undefined
					? res.data.Famiglia
					: res.data.FAMIGLIA != undefined
					? res.data.FAMIGLIA
					: [];
			this.fornitura_list = res.data.FORNITURA_STANDARD;
			this.tipoList = res.data.TIPOARTICOLO;
			this.stato_list = res.data.COMMESSA_STATO;
			this.stato_list.map((it) => {
				if (it.id_tabella === this.id_stato) {
					this.des_stato = it.des;
				}
			});
		});
		// IN order to get Macchina Lista
		let manu_detail = {
			controller: 'Manutenzione',
			method: 'POST',
			richiesta: {
				action: 'getlistamacchine',
				token: localStorage.getItem('user_token'),
				db_name: 'quickproduction',
			},
		};
		this.$store.dispatch('api', manu_detail).then((res) => {
			this.itemList = res.data;
			this.itemList.map((el) => {
				this.des_stato_list.push(el.des_stato);
			});
		});
		this.des_stato_list.filter((item, index) => {
			if (this.des_stato_list.indexOf(item) == index && item !== null) return item != null;
		});
	},
};
</script>
<style lang="scss">
.inputClass input {
	color: grey !important;
	box-shadow: none !important;
	border-bottom: none !important;
	outline: none !important;
}
.inputClass.v-input .v-input__slot {
	border-bottom: none !important;
	position: relative !important;
}
.inputClass label {
	color: black !important;
	font-weight: bold !important;
}
.dataClass label {
	color: black !important;
	font-weight: bold !important;
}
.artDialog{
	overflow-x: hidden;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	max-height: 100% !important;
}
.pdfdialog{
	overflow: hidden;
}
</style>
