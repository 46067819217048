<template>
  <v-container fluid class="py-0">
    <!--Dialog In order to Replace existing fasi with new ciclostd fasi or add as child -->
    <!-- Dialog to show list of fasi standard -->
    <v-dialog v-model="dailogciclostandardconfirm" width="50%">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text"
            >Aggiunta / Sostituzione Cicli Standard</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                small
                @click="dailogciclostandardconfirm = false"
                ><v-icon color="error">mdi-close</v-icon></v-btn
              >
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="setAsChild" small>Aggiungi</v-btn>
          <v-btn color="warning" @click="setAsCicloNew" small>sostituire</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog to show list of fasi standard -->
    <v-dialog v-model="dialogciclistandard" width="75%" content-class="dialogshadow">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text">Cicli Standard</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                small
                @click="dialogciclistandard = false"
                ><v-icon color="error">mdi-close</v-icon></v-btn
              >
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text style="max-height: 600px; overflow-y: auto">
          <v-data-table :headers="cicloheaders" :items="cicloitems">
            <template v-slot:[`item.edit`]="{ item }">
              <v-col md="4">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      style="float: left"
                      @click="openconfimationCicloStd(item)"
                      small
                    >
                      <v-icon color="success">mdi-check</v-icon>
                    </v-btn>
                  </template>
                  <span>Aggiungi</span>
                </v-tooltip>
              </v-col>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Dialog to show list of fasi Standard Table in Ciclo Commessa Produzione -->
    <v-dialog v-model="dialogfasestandard1" width="75%" content-class="dialogshadow">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text">Fasi Standard</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                small
                @click="dialogfasestandard1 = false"
                ><v-icon color="error">mdi-close</v-icon></v-btn
              >
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text style="max-height: 600px; overflow-y: auto">
          <v-data-table :headers="fasiHeaders" :items="fasiStdLista">
            <template v-slot:[`item.edit`]="{ item }">
              <v-col md="4">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      style="float: left"
                      @click="addToCicli(item)"
                      small
                    >
                      <v-icon color="primary">mdi-check</v-icon>
                    </v-btn>
                  </template>
                  <span>Aggiungi</span>
                </v-tooltip>
              </v-col>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Dialog to show list of fasi standard Table in Ciclo Commessa Produzione -->
    <v-dialog v-model="dialogfasestandard" width="75%" content-class="dialogshadow">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text">Fasi Standard</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-btn small @click="showFasi">
            <v-icon color="success">mdi-floppy</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                small
                @click="dialogfasestandard = false"
                ><v-icon color="error">mdi-close</v-icon></v-btn
              >
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text style="max-height: 600px; overflow-y: auto">
          <v-data-table
            v-model="selected"
            item-key="des_fase_standard"
            :headers="fasiHeaders"
            :items="fasiStdLista"
            :single-select="singleSelect"
            show-select
          >
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      content-class="artDialog"
      v-model="dialogCiclo"
      :scrollable="true"
      persistent
      width="1400"
    >
      <v-container fluid>
        <form>
          <v-row style="height: 100% !important">
            <v-card>
              <v-toolbar dense class="indigo darken-1" primary-title uppercase>
                <v-toolbar-title class="white--text">
                  Ciclo Commessa Produzione
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <Button
                  classe_bottone="mr-2"
                  colore_icona="primary"
                  idForm="COMMESSA_CICLO"
                  idOggetto="AGGIUNGI_FASE_STANDARD"
                  mdi_icona="mdi-shape-circle-plus"
                  testo_tooltip="Aggiungi fase standard"
                  v-on:buttonClick="
                    getFaseStd();
                    dialogfasestandard1 = true;
                  "
                />
                <Button
                  colore_icona="success"
                  idForm="COMMESSA_CICLO"
                  idOggetto="SALVA_FASE"
                  mdi_icona="mdi-floppy"
                  testo_tooltip="Salva Fase"
                  v-on:buttonClick="UpdateCicloFasi"
                />
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn small @click="closeDialog">
                      <v-icon depressed v-on="on" v-bind="attrs" color="error"
                        >mdi-window-close</v-icon
                      >
                    </v-btn>
                  </template>
                  <span>Cancellare</span>
                </v-tooltip>
              </v-toolbar>
              <v-card-text
                class=""
                style="max-height: 600px; overflow-y: auto; overflow-x: hidden"
              >
                <v-row>
                  <v-col md="1" class="py-0" cols="2" sm="2">
                    <TextField
                      idForm="COMMESSA_CICLO"
                      idOggetto="CC_COD_FASE"
                      label_input="Cod. Fase"
                      palceholder_input="Cod. Fase"
                      :vmodel="cod_fase"
                      v-on:changeText="(val) => (this.cod_fase = val)"
                      :min_input="0"
                      type_input="number"
                    />
                  </v-col>
                  <v-col md="2" class="py-0" sm="3" cols="3">
                    <v-autocomplete
                      v-model="des1"
                      :items="produzione_list"
                      label="Tipo Produzione"
                      placeholder="Tipo Produzione"
                      item-text="des"
                      required
                    >
                      <template #label>
                        <span class="">Tipo Produzione<strong>* </strong></span>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col class="py-0" md="1" sm="2" cols="2">
                    <div class="ml-n9">
                      <span style="font-size: 12px; color: rgba(0, 0, 0, 0.6)"
                        >Prelievo Comp.
                      </span>
                      <v-switch
                        v-model="materia_prima"
                        style="margin-top: -0.3rem !important"
                        class="mt-n1 ml-10"
                        :false-value="0"
                        :true-value="1"
                      ></v-switch>
                    </div>
                  </v-col>
                  <v-col class="py-0" md="1" cols="2" sm="2">
                    <TextField
                      idForm="COMMESSA_CICLO"
                      idOggetto="CC_TEMPO_STANDARD"
                      label_input="Tempo Standard"
                      palceholder_input="Tempo"
                      :vmodel="tempo_standard"
                      v-on:changeText="(val) => (this.tempo_standard = val)"
                      :min_input="0"
                      type_input="number"
                    />
                  </v-col>
                  <v-col class="py-0" md="1" sm="3" cols="3">
                    <v-autocomplete
                      v-model="um_tempo"
                      :items="unita_misura_lead_time"
                      label="UM Tempo"
                      placeholder="UM Tempo"
                      item-text="des"
                      required
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col class="py-0" sm="2" cols="2" md="1">
                    <TextField
                      idForm="COMMESSA_CICLO"
                      idOggetto="CC_PESO"
                      label_input="Peso"
                      palceholder_input="Peso"
                      :vmodel="peso"
                      v-on:changeText="(val) => (this.peso = val)"
                      :min_input="0"
                      type_input="number"
                    />
                  </v-col>
                  <v-col class="py-0" md="1" sm="2" cols="2">
                    <v-autocomplete
                      v-model="um_pezzo"
                      :items="unita_misura_peso"
                      label="UM Peso"
                      placeholder="UM Peso"
                      item-text="des"
                      required
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col md="3" class="py-0" sm="3" cols="3">
                    <v-autocomplete
                      v-model="des"
                      :items="fase_list"
                      label="Fase. Lavo"
                      placeholder="Fase. Lavo"
                      item-text="des"
                      required
                    >
                      <template #label>
                        <span class="">Fase. Lavo<strong>* </strong></span>
                        <a @click.stop style="pointer-events: all">
                          <Modules
                            @aggiornamento="aggiornaFase"
                            v-on:dialogModules="aggiornaFase"
                            tipo="FASE"
                            nome="Fase"
                            setaction="settabelle"
                            getaction="gettabelle"
                            controller="Magazzino"
                            filter="validita = 1"
                            :dati="[{ des: '' }]"
                          />
                        </a>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col class="py-0 ml-n2" md="1" sm="2">
                    <div class="">
                      <span
                        style="
                          font-size: 12px;
                          color: rgba(0, 0, 0, 0.6);
                          margin-left: -2.2rem;
                          padding-left: -0.2rem;
                        "
                        >Fase Esterna
                      </span>
                      <v-switch
                        v-model="fase_esterna"
                        style="margin-top: -0.3rem !important"
                        class="mt-n1"
                        :false-value="0"
                        :true-value="1"
                      ></v-switch>
                    </div>
                  </v-col>
                  <v-col cols="3" md="3" dense class="mt-n4 py-0">
                    <v-menu
                      dense
                      v-model="menu4"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          :value="formatdataScadenza"
                          label="Data Scadenza"
                          prepend-icon="event"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        style="width: 100% !important"
                        v-model="data_scadenza"
                        @input="menu4 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12" class="mt-n7" style="">
                    <Fornitura
                      FORM_ID="COMMESSA"
                      SOTTO_FORM="COMMESSA_CICLO"
                      :FORM_BUTTON_ARRAY="FORM_BUTTON_ARRAY"
                      v-on:updateFornitura="updateFornitura($event)"
                      :key="id_temp"
                      :data="Fornitoridata"
                      v-on:delFornitura="deleteFornituraLista($event)"
                    />
                  </v-col>
                  <v-col class="mt-n5" md="6">
                    <Macchina
                      FORM_ID="COMMESSA"
                      SOTTO_FORM="COMMESSA_CICLO"
                      :MACCHINA_BUTTON_ARRAY="MACCHINA_BUTTON_ARRAY"
                      v-on:updateMacchina="updateMacchina($event)"
                      :key="id_commessa_dettaglio"
                      :data="Macchinadata"
                      :commessa_form="commessa_form"
                      :commessa_data="cicloData"
                      v-on:delMacchina="delMacchina($event)"
                    />
                  </v-col>
                  <v-col class="mt-n5" md="6">
                    <Personale
                      FORM_ID="COMMESSA"
                      SOTTO_FORM="COMMESSA_CICLO"
                      :PERSONALE_BUTTON_ARRAY="PERSONALE_BUTTON_ARRAY"
                      v-on:updatePersonale="updatePersonale($event)"
                      :key="id_commessa_dettaglio"
                      :data="commessa_dettaglio_personale"
                      v-on:delPersonale="delPersonale($event)"
                    />
                  </v-col>
                  <v-col md="12" class="ml-1">
                    <v-textarea
                      rows="4"
                      v-model="note"
                      item-text="text"
                      label="Annotanzione"
                      placeholder="Annotanzione"
                      required
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-row>
        </form>
      </v-container>
    </v-dialog>
    <form>
      <v-row>
        <v-col md="12">
          <v-hover>
            <template v-slot="{ hover }">
              <v-card :elevation="hover ? 24 : 2">
                <v-toolbar dense class="indigo darken-1" primary-title>
                  <v-toolbar-title class="white--text"
                    >Ciclo Commessa Produzione</v-toolbar-title
                  >
                  <v-spacer></v-spacer>
                  <Button
                    classe_bottone="ml-2"
                    colore_icona="primary"
                    idForm="COMMESSA_CICLO"
                    idOggetto="RECUPERA_FASI"
                    mdi_icona="mdi-plus-circle-multiple-outline"
                    testo_tooltip="Recupera Fasi"
                    v-on:buttonClick="setFasiToIntialState"
                  />
                  <Button
                    classe_bottone="ml-2"
                    colore_icona="error"
                    idForm="COMMESSA_CICLO"
                    idOggetto="CANCELLA_FASI"
                    mdi_icona="mdi-close-circle-multiple"
                    testo_tooltip="Cancella Fasi"
                    v-on:buttonClick="delAllFasi"
                  />
                  <v-spacer></v-spacer>
                  <Button
                    colore_icona="primary"
                    idForm="COMMESSA_CICLO"
                    idOggetto="CREA_FASE"
                    mdi_icona="mdi-plus"
                    testo_tooltip="Nuova Fase"
                    v-on:buttonClick="opendialogciclo"
                  />
                  <Button
                    classe_bottone="ml-2"
                    colore_icona="primary"
                    idForm="COMMESSA_CICLO"
                    idOggetto="AGGIUNGI_CICLO_STANDARD"
                    mdi_icona="mdi-vector-circle-variant"
                    testo_tooltip="Aggiungi ciclo standard"
                    v-on:buttonClick="
                      getCicloStd();
                      dialogciclistandard = true;
                    "
                  />
                  <Button
                    classe_bottone="ml-2"
                    colore_icona="primary"
                    idForm="COMMESSA_CICLO"
                    idOggetto="AGGIUNGI_FASE_STANDARD"
                    mdi_icona=" mdi-shape-circle-plus "
                    testo_tooltip="Aggiungi fase standard"
                    v-on:buttonClick="
                      getFaseStd();
                      dialogfasestandard = true;
                    "
                  />
                  <v-spacer></v-spacer>
                </v-toolbar>
                <v-data-table
                  :loading="loadingCiclo"
                  :headers="CicloHeaders"
                  :items="cicloLista"
                >
                  <v-progress-linear
                    v-show="progressBarCiclo"
                    slot="progress"
                    color="blue"
                    indeterminate
                  ></v-progress-linear>
                  <template v-slot:[`item.edit`]="{ item }">
                    <Button
                      colore_icona="primary"
                      idForm="COMMESSA_CICLO"
                      idOggetto="MODIFICA_FASE"
                      mdi_icona=" mdi-pencil"
                      testo_tooltip="Aggiornare"
                      v-on:buttonClick="
                        handleClick(item);
                        Fornitoridata = item.commessa_dettaglio_fornitori;
                        Macchinadata = item.commessa_dettaglio_macchina;
                        id_commessa_dettaglio = item.id_commessa_dettaglio
                          ? item.id_commessa_dettaglio
                          : -1;
                      "
                    />
                  </template>
                  <template v-slot:[`item.materia_prima`]="{ item }">
                    <v-icon v-if="item.materia_prima == 1"
                      >mdi-check-underline</v-icon
                    >
                  </template>
                  <template v-slot:[`item.fase_esterna`]="{ item }">
                    <v-icon v-if="item.fase_esterna == 1"
                      >mdi-check-underline</v-icon
                    >
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <Button
                      colore_icona="error"
                      idForm="COMMESSA_CICLO"
                      idOggetto="ELIMINA_FASE"
                      mdi_icona=" mdi-window-close"
                      testo_tooltip="Cancellare"
                      v-on:buttonClick="delcommessa(item)"
                    />
                  </template>
                </v-data-table>
              </v-card>
            </template>
          </v-hover>
        </v-col>
      </v-row>
    </form>
    <v-snackbar
      content-class="snackbar"
      :color="snackbar_background"
      right
      v-model="snackbar"
      :timeout="3000"
    >
      <v-icon :color="snackbar_icon_color" :size="64">{{
        snackbar_icon
      }}</v-icon>
      <h2 :style="{ color: snackbar_text_color + '!important' }" class="ml-16">
        {{ snackbar_text }}
      </h2>
      <v-btn
        @click="snackbar = false"
        fab
        dark
        text
        small
        style="
          margin-top: -6rem;
          float: right !important;
          margin-right: -1rem !important;
        "
      >
        <v-icon :color="snackbar_icon_color">mdi-window-close</v-icon>
      </v-btn>
    </v-snackbar>
  </v-container>
</template>
<script>
import Button from "../../../components/TOOLS/FormComponentPermissions/Button.vue";
import TextField from "../../../components/TOOLS/FormComponentPermissions/TextField";
import moment from "moment";
import Fornitura from "../Common/Fornitura.vue";
import Macchina from "../Common/Macchina.vue";
import Personale from "../Common/Personale.vue";
export default {
  props: ["cicloData", "IdArticolo"],
  components: {
    Modules: () => import("../../../components/TOOLS/Modules"),
    Button,
    TextField,
    Macchina,
    Fornitura,
    Personale,
  },
  data() {
    return {
      //<==============================Ciclo Variables=====================>//
      FORM_BUTTON_ARRAY: [
        "CREA_COMMESSA_FASE_FORNITURA",
        "MODIFICA_COMMESSA_FASE_FORNITURA",
        "ELIMINA_COMMESSA_FASE_FORNITURA",
        "SALVA_COMMESSA_FASE_FORNITURA",
      ],
      MACCHINA_BUTTON_ARRAY: [
        "CREA_COMMESSA_FASE_MACCHINA",
        "MODIFICA_COMMESSA_FASE_MACCHINA",
        "ELIMINA_COMMESSA_FASE_MACCHINA",
        "SALVA_COMMESSA_FASE_MACCHINA",
      ],
      PERSONALE_BUTTON_ARRAY: [
        "CREA_COMMESSA_FASE_PERSONALE",
        "MODIFICA_COMMESSA_FASE_PERSONALE",
        "ELIMINA_COMMESSA_FASE_PERSONALE",
        "SALVA_COMMESSA_FASE_PERSONALE",
      ],
      // data_scadenza var
      commessa_form: true,
      data_scadenza: "",
      menu4: false,
      temp_array: [],
      newArray: this.cicloData.commessa_dettaglio,
      cicloLista1: [],
      cicloLista: [],
      tipo_produzione_list: [],
      fase_lavorazione_list: [],
      progressBarCiclo: false,
      loadingCiclo: false,
      dialogCiclo: false,
      id_ciclo_fasi: "",
      cod_fase: "",
      produzione_list: [],
      id_tipo_fase: "",
      id_fase: "",
      fase_list: [],
      search: "",
      peso: "",
      unita_misura_peso: [],
      des: "",
      des1: "",
      cod_macchina: null,
      costo_lavorazione: null,
      fase_esterna: 0,
      materia_prima: 0,
      tempo_standard: 0,
      id_um_tempo: "",
      des_um_tempo: "",
      id_um_pezzo: "",
      um_pezzo: "",
      singleitem: {},
      unita_misura_lead_time: [],
      note: "",
      // Variable for pop modal for deletion
      dialogdeletecicloconfirmation: false,
      selectedCiclo: {},
      //<======== Fornitura Variables =====>//
      id_commessa_dettaglio: -1,
      ID_COMMESSA: -1,
      Fornitoridata: [],
      commessa_dettaglio_fornitori: [],
      fornitoriLista: [],
      deletedFornitura: [],
      // Macchina Variables
      commessa_dettaglio_macchina: [],
      macchina_predefinito: false,
      articolo_ciclo_fasi_macchina: [],
      singleMacchina: {},
      deletedMacchina: [],
      Macchinadata: [],
      // Personale Variables
      commessa_dettaglio_personale: [],
      deletedPersonale: [],
      // Variabili d'appoggio
      index_sel: null,
      //  <=======================Generic Variables =====================>
      // cicloheaders
      cicloheaders: [],
      cicloitems: [],
      // Dialog Ciclo Standard
      dialogciclistandard: false,
      // Dailog Ciclo Standard Confirmation
      dailogciclostandardconfirm: false,
      selectedCicloStd: {},
      // Dailog Fase Standard
      singleSelect: false,
      selected: [],
      dialogfasestandard: false,
      dialogfasestandard1: false,
      item: "",
      overlay: false,
      id_articolo: this.IdArticolo,
      validita: 1,
      // Confirmation Dialog
      dialogSavingConfirmation: false,
      // Snackbar Variables
      snackbar: false,
      snackbar_background: "",
      snackbar_icon: "",
      snackbar_icon_color: "",
      snackbar_text: "",
      snackbar_text_color: "",
      color: "",
      id_temp: "",
      um_tempo: "",
      id_temp_dett: "",
      // Fasi Standard List and Fasi standard Headers
      fasiStdLista: [],
      fasiHeaders: [],
      FaseStandDetail: null,
      // Header of ciclo table
      CicloHeaders: [
        {
          value: "edit",
        },
        {
          text: "Cod. Fase",
          value: "cod_fase",
          sortable: false,
        },
        {
          text: "Tipo Fase",
          value: "des1",
          sortable: false,
        },
        {
          text: "Prelievo Comp",
          value: "materia_prima",
          sortable: false,
        },
        {
          text: "Lavoro Esterna",
          value: "fase_esterna",
          sortable: false,
        },
        {
          text: "Fase Lavoro",
          value: "des",
          sortable: false,
        },
        {
          text: "Peso",
          value: "peso",
          sortable: false,
        },
        {
          text: "Lotto Prod",
          value: "lotto_produzione",
          sortable: false,
        },
        {
          text: "Data Scadenza",
          value: "data_scadenza",
          sortable: false,
        },
        {
          text: "Ragione Sociale",
          value: "ragione_sociale",
          sortable: false,
        },
        {
          text: "Trasf. Diretto",
          value: "trasf_diretto",
          sortable: false,
        },
        {
          text: "Lead Time",
          value: "lead_time",
          sortable: false,
        },
        {
          text: "Lotto Lavoro",
          value: "lotto_lavorazione",
          sortable: false,
        },
        {
          text: "Costo Lavoro",
          value: "costo_lavorazione",
          sortable: false,
        },
        {
          text: "Tempo Standard",
          value: "tempo_standard",
          sortable: false,
        },
        {
          text: "Pezzi/h Min",
          value: "pezzi_ora_min",
          sortable: false,
        },
        {
          text: "Pezzi/h Max",
          value: "pezzi_ora_max",
          sortable: false,
        },
        {
          text: "Macchina",
          value: "codice",
          sortable: false,
        },
        {
          text: "Tempo Setup",
          value: "tempo_setup",
          sortable: false,
        },
        {
          text: "Attrezzatura",
          value: "id_attrezzatura",
          sortable: false,
        },
        {
          value: "actions",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    // populating deletedPersonale array if there is any element
    delPersonale(val) {
      this.deletedPersonale = val;
    },
    // Getting the Personale list to store on BE
    updatePersonale(val) {
      this.commessa_dettaglio_personale = val;
    },
    // populating deletedmacchina array if there is any element
    delMacchina(val) {
      this.deletedMacchina = val;
    },
    // Getting the macchina list to store on BE
    updateMacchina(val) {
      this.commessa_dettaglio_macchina = val;
    },
    //   Delete Fornitura populating
    deleteFornituraLista(val) {
      this.deletedFornitura = val;
    },
    // Getting updated Fornitura Lista
    updateFornitura(val) {
      this.commessa_dettaglio_fornitori = val;
    },
    // Open Ciclo Std Confirmation Dialog
    openconfimationCicloStd(item) {
      this.dailogciclostandardconfirm = true;
      this.selectedCicloStd = item;
    },
    setAsChild() {
      let getCicloStd = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getciclostd",
          token: localStorage.getItem("user_token"),
          id_ciclostd: this.selectedCicloStd.id_ciclostd,
          id_articolo: "",
          tipo_import: "",
        },
      };
      this.$store.dispatch("api", getCicloStd).then((res) => {
        let data = res.data.ciclostd[0].ciclostd_dettaglio;
        this.selected = data;
        this.showFasi();
        this.dailogciclostandardconfirm = false;
        this.dialogciclistandard = false;
      });
    },
    // To set Ciclo Std as New ciclo or replace with existing ciclo
    setAsCicloNew() {
      this.delAllFasi();
      let getCicloStd = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getciclostd",
          token: localStorage.getItem("user_token"),
          id_ciclostd: this.selectedCicloStd.id_ciclostd,
          id_articolo: "",
          tipo_import: "",
        },
      };
      this.$store.dispatch("api", getCicloStd).then((res) => {
        this.selected = res.data.ciclostd[0].ciclostd_dettaglio;
        this.showFasi();
        this.cicloData.commessa_dettaglio = this.cicloLista;
        this.dailogciclostandardconfirm = false;
        this.dialogciclistandard = false;
      });
    },
    // Show All Ciclo Standard
    getCicloStd() {
      let getCicolo = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getciclostd",
          token: localStorage.getItem("user_token"),
          id_ciclostd: "",
          id_articolo: "",
          tipo_import: "",
        },
      };
      this.$store.dispatch("api", getCicolo).then((res) => {
        const data = res.data.lista;
        this.cicloheaders = res.data.header;
        let data2 = {
          value: "edit",
          sortable: false,
        };
        this.cicloheaders.unshift(data2);
        let data1 = {
          value: "delete",
          sortable: false,
        };
        this.cicloheaders.push(data1);
        this.cicloitems = data;
        this.overlay = false;
        this.dialogciclistandard = true;
      });
    },
    // To set the cicloLista to initial stage
    setFasiToIntialState() {
      if (this.cicloLista.length > 0) {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_color = "error";
        this.snackbar_icon = "mdi-window-close";
        this.snackbar_text =
          "è necessario attuare Cancella Fasi prima del Recupera Fasi";
        return;
        // alert("You must perfrom Cancella Fasi before Recupera Fasi")
      }
      // if (this.$route.params.id == -1) {
      let Getnewcommesa = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getnewcommessa",
          token: localStorage.getItem("user_token"),
          id_articolo: this.cicloData.commessa[0].id_articolo,
        },
      };
      this.$store.dispatch("api", Getnewcommesa).then((res) => {
        this.cicloLista = res.data.commessa_dettaglio;
        this.cicloData.commessa_dettaglio = this.cicloLista;
        this.cicloLista.sort(
          (a, b) => parseFloat(a.cod_fase) - parseFloat(b.cod_fase)
        );
      });
      // }
      // if (this.$route.params.id >= 1) {
      // 	let getcommessa = {
      // 		controller: 'Magazzino',
      // 		method: 'POST',
      // 		richiesta: {
      // 			action: 'getcommessafull',
      // 			token: localStorage.getItem('user_token'),
      // 			id_commessa: this.$route.params.id,
      // 			id_profilo: localStorage.id_profilo,
      // 		},
      // 	};
      // 	this.$store.dispatch('api', getcommessa).then((res) => {
      // 		this.cicloLista = res.data.commessa_dettaglio;
      // 		this.cicloLista.sort((a, b) => parseFloat(a.cod_fase) - parseFloat(b.cod_fase));
      // 	});
      // }
    },
    // showFasi
    delAllFasi() {
      this.newArray = [];
      this.cicloData.commessa_dettaglio = [];
      this.cicloLista.map((it) => {
        if (it.id_commessa_dettaglio > 0) {
          it.validita = 0;
          this.newArray.push(it);
          this.$emit("deleteAllFasi", this.newArray);
        }
      });
      this.cicloLista = [];
    },
    showFasi() {
      this.overlay = true;
      this.selected.map((it) => {
        let getArticoloStandardDettaglioFromFaseStandard = {
          controller: "Magazzino",
          method: "POST",
          richiesta: {
            action: "getfasestd",
            token: localStorage.getItem("user_token"),
            id_ciclostd_dettaglio: it.id_ciclostd_dettaglio,
            id_articolo: this.id_articolo,
            tipo_import: "C",
          },
        };
        this.$store
          .dispatch("api", getArticoloStandardDettaglioFromFaseStandard)
          .then((res) => {
            this.overlay = false;
            this.FaseStandDetail = res.data.commessa_dettaglio[0];
            const i = this.cicloLista.find(
              (it) => it.cod_fase == this.FaseStandDetail.cod_fase
            );
            if (i) {
              this.FaseStandDetail.cod_fase++;
              let last_item = this.cicloLista[this.cicloLista.length - 1];
              let last_cod_fase = last_item.cod_fase;
              if (this.cicloData.commessa[0].id_commessa > -1) {
                this.FaseStandDetail.id_commessa = this.cicloData.commessa[0].id_commessa;
              } else {
                this.FaseStandDetail.id_commessa = -1;
              }
              this.FaseStandDetail.cod_fase = last_cod_fase += 10;
              this.cicloLista.push(this.FaseStandDetail);
              this.cicloLista.sort(
                (a, b) => parseFloat(a.cod_fase) - parseFloat(b.cod_fase)
              );
              return;
            } else {
              if (this.cicloData.commessa[0].id_commessa > -1) {
                this.FaseStandDetail.id_commessa = this.cicloData.commessa[0].id_commessa;
              } else {
                this.FaseStandDetail.id_commessa = -1;
              }
              this.cicloLista.push(this.FaseStandDetail);
              this.cicloLista.sort(
                (a, b) => parseFloat(a.cod_fase) - parseFloat(b.cod_fase)
              );
            }
          });
      });
      this.cicloLista &&
        this.cicloLista.sort(
          (a, b) => parseFloat(a.cod_fase) - parseFloat(b.cod_fase)
        );
      this.dialogfasestandard = false;
      this.selected = [];
    },
    // In order to get Fasi Standard
    getFaseStd() {
      let getCicolo = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getfasestd",
          token: localStorage.getItem("user_token"),
          id_ciclostd_dettaglio: "",
        },
      };
      this.$store.dispatch("api", getCicolo).then((res) => {
        const data = res.data.lista;
        this.fasiHeaders = res.data.header;
        let data2 = {
          value: "edit",
          sortable: false,
        };
        this.fasiHeaders.unshift(data2);
        let data1 = {
          value: "delete",
          sortable: false,
        };
        this.fasiHeaders.push(data1);
        data.sort((a, b) => parseFloat(a.cod_fase) - parseFloat(b.cod_fase));
        this.fasiStdLista = data;
        if (this.cicloLista.length > 0) {
          this.progressBarCiclo = false;
          this.loadingCiclo = false;
        }
        if (this.cicloLista.length === 0) {
          this.progressBarCiclo = false;
          this.loadingCiclo = false;
        }
      });
    },
    // In order to Add Fase Standard into Ciclo table
    addToCicli(item) {
      if (this.dialogCiclo === true) {
        this.overlay = true;
        let getFaseStandardDettaglio = {
          controller: "Magazzino",
          method: "POST",
          richiesta: {
            action: "getfasestd",
            token: localStorage.getItem("user_token"),
            id_ciclostd_dettaglio: item.id_ciclostd_dettaglio,
            id_articolo: this.id_articolo,
            tipo_import: "C",
          },
        };
        this.$store.dispatch("api", getFaseStandardDettaglio).then((res) => {
          this.overlay = false;
          res.data.commessa_dettaglio[0].id_temp = "";
          this.handleClick(res.data.commessa_dettaglio[0]);
        });
      }
      this.dialogfasestandard1 = false;
    },
    // in order to add or remove to fase lavorazione list
    aggiornaFase() {
      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "gettabelle",
          token: localStorage.getItem("user_token"),
          tipo: "FASE",
          filter: "validita = 1",
        },
      };
      this.$store.dispatch("api", request).then((res) => {
        this.fase_list = res.data;
        this.fase_list.map((el) => {
          this.fase_lavorazione_list.push(el.des);
        });
      });
    },
    // in  order to del on fasi
    delcommessa(item) {
      if (item.id_commessa === -1) {
        const i = this.cicloLista.findIndex(
          (it) => it.id_temp === item.id_temp
        );
        this.cicloLista.splice(i, 1);
      }
      if (item.id_commessa > -1) {
        let deletedCicolo = [];
        item.validita = 0;
        deletedCicolo.push(item);
        const i = this.cicloLista.findIndex(
          (it) => it.id_temp === item.id_temp
        );
        this.cicloLista.splice(i, 1);
        this.$emit("deletedCiclo", deletedCicolo);
      }
    },

    // in order to clear ciclo dialog
    opendialogciclo() {
      this.Macchinadata = [];
      this.Fornitoridata = [];
      this.id_temp = "";
      this.id_um_tempo = "";
      this.id_um_pezzo = "";
      this.um_tempo = "";
      this.um_pezzo = "";
      this.cod_fase = "";
      this.codice = "";
      this.des1 = "";
      this.materia_prima = 0;
      this.tempo_standard = "";
      this.peso = "";
      this.des = "";
      this.fase_esterna = 0;
      this.id_commessa_dettaglio = "";
      this.dialogCiclo = true;
      this.note = "";
      this.singleitem = {};
      this.commessa_dettaglio_fornitori = [];
      this.commessa_dettaglio_macchina = [];
      this.commessa_dettaglio_personale = [];
    },
    // clear form when close unsaved form with cancel button
    closeDialog() {
      this.dialogCiclo = false;
      this.articolo_ciclo_fasi_macchina = [];
      this.codice = "";
      this.des1 = "";
      this.materia_prima = "";
      this.tempo_standard = "";
      this.des = "";
      this.fase_esterna = 0;
    },
    // setting vale to fasi dailog at edit
    handleClick(value) {
      this.dialogCiclo = true;
      this.singleitem = value;
      this.cod_fase = this.singleitem.cod_fase;
      this.des1 = this.singleitem.des1;
      this.des = this.singleitem.des;
      this.data_scadenza = this.singleitem.data_scadenza
        ? this.singleitem.data_scadenza
        : "";
      this.materia_prima = this.singleitem.materia_prima;
      if (this.materia_prima == "1") {
        this.materia_prima = 1;
      }
      if (this.materia_prima == "0") {
        this.materia_prima = 0;
      }
      this.tempo_standard = this.singleitem.tempo_standard;
      this.peso = this.singleitem.peso;
      this.fase_esterna = this.singleitem.fase_esterna;
      if (this.fase_esterna == "1") {
        this.fase_esterna = 1;
      }
      if (this.fase_esterna == "0") {
        this.fase_esterna = 0;
      }
      this.id_commessa_dettaglio = this.singleitem.id_commessa_dettaglio;
      this.note = this.singleitem.note;
      this.id_temp = this.singleitem.id_temp;
      this.um_tempo = this.singleitem.um_tempo;
      this.um_pezzo = this.singleitem.um_pezzo;
      this.Fornitoridata = value.commessa_dettaglio_fornitori;
      this.Macchinadata = value.commessa_dettaglio_macchina;
      this.commessa_dettaglio_fornitori = value.commessa_dettaglio_fornitori;
      this.commessa_dettaglio_fornitori.map((it) => {
        if (it.predefinito == "0") {
          it.predefinito = 0;
        }
        if (it.predefinito == "1") {
          it.predefinito = 1;
        }
      });
      this.commessa_dettaglio_macchina = value.commessa_dettaglio_macchina;
      this.commessa_dettaglio_macchina.map((it) => {
        if (it.predefinito == "0") {
          it.predefinito = 0;
        }
        if (it.predefinito == "1") {
          it.predefinito = 1;
        }
      });
      this.commessa_dettaglio_personale = value.commessa_dettaglio_personale;
    },
    // check if cod fase is duplicated
    checkcodfase(item) {
      this.cicloLista &&
        this.cicloLista.map((it) => {
          if (it.cod_fase == item.cod_fase && it.id_ciclo_fasi == -1) {
            this.snackbar = true;
            this.snackbar_color = "error";
            this.snackbar_icon = "mdi-window-close";
            this.snackbar_text =
              "Non è possibile salvare due cicli con lo stesso codice fase";
            return;
          }
          if (it.cod_fase == item.cod_fase && item.id_temp != it.id_temp) {
            this.snackbar = true;
            this.snackbar_color = "error";
            this.snackbar_icon = "mdi-window-close";
            this.snackbar_text =
              "Non è possibile salvare due cicli con lo stesso codice fase";
            return;
          }
        });
    },
    // in order to save or update fasi
    UpdateCicloFasi() {
      // Controllo che il Tipo Produzione e la Fase Lavorazione
      if (this.des1 == "" || this.des == "") {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text =
          "Definire almeno il Tipo Produzione e la Fase Lavorazione prima di salvare.";
        this.snackbar_text_color = "white";
        return;
      } else {
        console.log(this.des1 + " e " + this.des);
      }
      // Controllo che id_temp non sia valorizzato
      if (!this.singleitem.id_temp) {
        if (this.cod_fase == "") {
          this.snackbar = true;
          this.snackbar_background = "orange";
          this.snackbar_icon = "mdi-alert";
          this.snackbar_icon_color = "white";
          this.snackbar_text = "Inserire il Cod. Fase prima di salvare.";
          this.snackbar_text_color = "white";
          return;
        }
        // Se il controllo viene superato, procedo al salvataggio
        let data = {};
        data.id_commessa_dettaglio = -1;
        data.id_commessa = this.cicloData.commessa.id_commessa
          ? this.cicloData.commessa.id_commessa
          : -1;
        data.data_pianificata = null;
        data.id_articolo = this.IdArticolo;
        this.produzione_list.map((prod) => {
          if (prod.des === this.des1) {
            data.id_tipo_fase = prod.id_tabella;
          }
        });
        data.materia_prima = this.materia_prima;
        data.fase_esterna = this.fase_esterna;
        data.cod_fase = this.cod_fase;
        this.fase_list.map((el) => {
          if (el.des === this.des) {
            data.id_fase = el.id_tabella;
          }
        });
        this.unita_misura_peso.map((it) => {
          if (it.des == this.um_pezzo) {
            data.id_um_pezzo = it.id_tabella;
            data.um_pezzo = it.des

          }
        });
        this.unita_misura_lead_time.map((mi) => {
          if (mi.des == this.um_tempo) {
            data.id_um_tempo = mi.id_tabella;
            data.um_tempo = mi.des
          }
        });
        data.peso = this.peso;
        data.lotto_produzione = null;
        data.id_macchina = null;
        data.file_inviato_macchina = null;
        data.id_attrezzatura = null;
        data.id_anagrafica = null;
        data.tempo_standard = this.tempo_standard;
        data.lead_time = null;
        data.lotto_lavorazione = null;
        data.costo_lavorazione = null;
        data.pezzi_ora_min = null;
        data.pezzi_ora_max = null;
        data.tempo_setup = null;
        data.data_scadenza = this.data_scadenza
          ? moment(this.data_scadenza).format("DD/MM/YYYY")
          : "";


          console.log(`id_um_tempo`, data.id_um_tempo)
        data.note = this.note;
        data.riga_fase = null;
        data.riga = null;
        data.validita = 1;
        data.id_temp = moment().unix();
        data.des = this.des;
        data.des1 = this.des1;
        data.um_pezzo = this.um_pezzo;
        data.um_tempo = this.um_tempo;
        console.log(`data`, data)
        this.checkcodfase(data);
        if (!this.fase_esterna) {
          var macchinawithpref = [];
          this.commessa_dettaglio_macchina.map((it) => {
            if (it.predefinito == 1) {
              macchinawithpref.push(it);
            }
          });
          if (macchinawithpref.length < 1) {
            this.snackbar = true;
            this.snackbar_background = "orange";
            this.snackbar_icon = "mdi-alert";
            this.snackbar_icon_color = "white";
            this.snackbar_text =
              "È necessario selezionare una macchina predefinita.";
            this.snackbar_text_color = "white";
            return;
          }
          this.commessa_dettaglio_fornitori.map((it) => (it.predefinito = 0));
        }
        if (this.fase_esterna) {
          var fornitoriwithpref = [];
          this.commessa_dettaglio_fornitori.map((it) => {
            if (it.predefinito == 1) {
              fornitoriwithpref.push(it);
            }
          });
          if (fornitoriwithpref.length < 1) {
            this.snackbar = true;
            this.snackbar_background = "orange";
            this.snackbar_icon = "mdi-alert";
            this.snackbar_icon_color = "white";
            this.snackbar_text =
              "È necessario selezionare una Fornitura predefinita.";
            this.snackbar_text_color = "white";
            return;
          }
          this.commessa_dettaglio_macchina.map((it) => (it.predefinito = 0));
        }
        data.commessa_dettaglio_personale = this.commessa_dettaglio_personale;
        data.commessa_dettaglio_macchina = this.commessa_dettaglio_macchina;
        data.commessa_dettaglio_fornitori = this.commessa_dettaglio_fornitori;
        this.dialog = false;
        if (this.snackbar) {
          return;
        }
        if (!this.snackbar) {
          this.cicloLista.push(data);
          this.dialogCiclo = false;
          this.cicloLista.sort(
            (a, b) => parseFloat(a.cod_fase) - parseFloat(b.cod_fase)
          );
          this.cicloData.commessa_dettaglio = this.cicloLista;
        }
      }
      if (this.singleitem.id_temp) {
        if (this.cod_fase == "") {
          this.snackbar = true;
          this.snackbar_background = "orange";
          this.snackbar_icon = "mdi-alert";
          this.snackbar_icon_color = "white";
          this.snackbar_text = "Inserire il Cod. Fase prima di salvare.";
          this.snackbar_text_color = "white";
          return;
        }
        let data = {};
        data.id_commessa_dettaglio = this.singleitem.id_commessa_dettaglio;
        data.id_commessa = this.singleitem.id_commessa;
        data.data_pianificata = null;
        data.id_articolo = this.IdArticolo;
        this.produzione_list.map((prod) => {
          if (prod.des === this.des1) {
            data.id_tipo_fase = prod.id_tabella;
          }
        });
        data.materia_prima = this.materia_prima;
        data.fase_esterna = this.fase_esterna;
        data.cod_fase = this.cod_fase;
        this.fase_list.map((el) => {
          if (el.des === this.des) {
            data.id_fase = el.id_tabella;
            return data;
          }
        });
        data.peso = this.peso;
        data.lotto_produzione = null;
        data.id_macchina = null;
        data.file_inviato_macchina = null;
        data.id_attrezzatura = null;
        data.id_anagrafica = null;
        data.tempo_standard = this.tempo_standard;
        data.lead_time = null;
        data.lotto_lavorazione = null;
        data.costo_lavorazione = null;
        data.data_scadenza = this.data_scadenza
          ? moment(this.data_scadenza, ["YYYY-MM-DD"]).format("DD/MM/YYYY")
          : "";
        data.pezzi_ora_max = null;
        data.pezzi_ora_min = null;
        data.tempo_setup = null;
        if (!this.um_pezzo) {
          data.id_um_pezzo = null;
        }
        this.unita_misura_peso.map((it) => {
          if (it.des === this.um_pezzo) {
            data.id_um_pezzo = it.id_tabella;
          }
        });
        if (!this.um_tempo) {
          data.id_um_tempo = null;
        }
        this.unita_misura_lead_time.map((mi) => {
          if (mi.des === this.um_tempo) {
            data.id_um_tempo = mi.id_tabella;
          }
        });
        data.note = this.note;
        data.riga_fase = null;
        data.riga = null;
        data.validita = 1;
        data.id_temp = this.singleitem.id_temp;
        // data.id_temp_dettaglio = moment.unix();
        data.des = this.des;
        data.des1 = this.des1;
        data.um_pezzo = this.um_pezzo;
        data.um_tempo = this.um_tempo;
        this.checkcodfase(data);
        if (this.deletedMacchina.length > 0) {
          this.$emit("delMacchina", this.deletedMacchina);
        }
        if (this.deletedPersonale.length > 0) {
          this.$emit("delPersonale", this.deletedPersonale);
        }
        if (this.deletedFornitura.length > 0) {
          this.$emit("delFornitura", this.deletedFornitura);
        }
        if (!this.fase_esterna) {
          let macchinawithpref = [];
          this.commessa_dettaglio_macchina.map((it) => {
            if (it.predefinito === 1) {
              macchinawithpref.push(it);
            }
          });
          if (macchinawithpref.length < 1) {
            this.snackbar = true;
            this.snackbar_background = "orange";
            this.snackbar_icon = "mdi-alert";
            this.snackbar_icon_color = "white";
            this.snackbar_text =
              "È necessario selezionare una macchina predefinita.";
            this.snackbar_text_color = "white";
            return;
          }
          this.commessa_dettaglio_fornitori.map((it) => (it.predefinito = 0));
        }
        if (this.fase_esterna) {
          let fornitoriwithpref = [];
          this.commessa_dettaglio_fornitori.map((it) => {
            if (it.predefinito === 1) {
              fornitoriwithpref.push(it);
            }
          });
          if (fornitoriwithpref.length < 1) {
            this.snackbar = true;
            this.snackbar_background = "orange";
            this.snackbar_icon = "mdi-alert";
            this.snackbar_icon_color = "white";
            this.snackbar_text =
              "È necessario selezionare una fornitura predefinita.";
            this.snackbar_text_color = "white";
            return;
          }
          this.commessa_dettaglio_macchina.map((it) => (it.predefinito = 0));
        }
        data.commessa_dettaglio_personale =
          this.commessa_dettaglio_personale.length === 0
            ? []
            : this.commessa_dettaglio_personale;
        data.commessa_dettaglio_macchina = this.commessa_dettaglio_macchina;
        data.commessa_dettaglio_fornitori = this.commessa_dettaglio_fornitori;
        if (!this.snackbar) {
          let i = this.cicloLista.findIndex(
            (it) => it.id_temp === data.id_temp
          );
          this.cicloLista.splice(i, 1, data);
          this.cicloLista.sort(
            (a, b) => parseFloat(a.cod_fase) - parseFloat(b.cod_fase)
          );
          this.cicloData.commessa_dettaglio = this.cicloLista;
        }
        this.dialog = false;
        this.dialogCiclo = false;
      }
    },
  },
  computed: {
    formatdataScadenza() {
      return this.data_scadenza
        ? moment(this.data_scadenza, ["YYYY-MM-DD", "YYYY-MM-DD"]).format(
            "DD/MM/YYYY"
          )
        : "";
    },
  },
  mounted() {
    // Geeting all autocomplete list values
    let request = {
      controller: "Magazzino",
      method: "POST",
      richiesta: {
        action: "gettabelle",
        token: localStorage.getItem("user_token"),
        tipo: "'UNITA_MISURA','TIPO_PRODUZIONE','FASE'",
        filter: "validita = 1",
      },
    };
    this.$store.dispatch("api", request).then((res) => {
      // Gestisco l'array che ricevo dal BE per il tipo produzione
      this.produzione_list = res.data.TIPO_PRODUZIONE;
      this.produzione_list.map((el) => {
        this.tipo_produzione_list.push(el.des);
      });
      // Gestisco l'array che ricevo dal BE per la fase
      this.fase_list = res.data.FASE;
      this.fase_list.map((el) => {
        this.fase_lavorazione_list.push(el.des);
      });
      // Gestisco l'array che ricevo dal BE per le unità di misura
      this.unita_misura_mod_list = res.data.UNITA_MISURA;
      this.unita_misura_mod_list.map((it) => {
        if (it.val_txt === "H") {
          this.unita_misura_lead_time.push(it);
        }
      });
      res.data.UNITA_MISURA.map((el) => {
        if (el.val_txt === "P") {
          this.unita_misura_peso.push(el);
        }
      });
    });
    this.cicloLista = this.cicloData.commessa_dettaglio;
    // this.newArray = this.cicloLista1.concat(this.cicloLista);
    this.cicloLista &&
      this.cicloLista.map((it) => (it.data_pianificata = null));
    this.cicloLista &&
      this.cicloLista.sort(
        (a, b) => parseFloat(a.cod_fase) - parseFloat(b.cod_fase)
      );
  },
};
</script>
<style lang="scss">
.artDialog {
  overflow-x: hidden;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}
</style>
