<template>
  <v-container fluid class="py-0">
    <v-row>
      <v-col>
        <v-card>
          <v-toolbar dense class="indigo darken-1 headline">
            <v-toolbar-title class="white--text">
              Commessa Annotazioni
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn class="mt-n2 " style="float:right" small>
                            <v-icon v-on="on" v-bind="attrs" dark tile color="success">mdi-floppy</v-icon>
                        </v-btn>
                    </template>
                    <span>Salva Annotazioni</span>
                </v-tooltip>
                <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
            <v-row>
              <v-col cols="12" md="12">
                <v-textarea
                  v-model="annotazioni"
                  label="Commessa Annotazioni"
                  placeholder="Annotazioni"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-flex class="text-xs-right"> </v-flex>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      annotazioni: "",
    };
  },
};
</script>
